import styled from "styled-components";
import { HandleProps, InputProps, StyleToggleProps } from "./types";

export const Handle = styled.div<HandleProps>`
  background-color: ${({ theme }) => theme.toggle.handleBackground};
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  width: 20px;
  left: 2px;
  position: absolute;
  top: 2px;
  transition: background-color 200ms ease-in, left 200ms ease-in;
  z-index: 1;
`;

export const Input = styled.input<InputProps>`
  cursor: pointer;
  opacity: 0;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 3;

  &:checked + ${Handle} {
    left: calc(100% - 22px);
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

const StyledToggle = styled.div<StyleToggleProps>`
  align-items: center;
  background: ${({ theme, $defaultColor }) => theme.colors[$defaultColor]};
  border-radius: 32px;
  box-shadow: ${({ theme }) => theme.shadows.inset};
  cursor: pointer;
  display: inline-flex;
  height: 24px;
  position: relative;
  width: 44px;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: inherit;
    background: ${({ theme, $checkedColor }) => theme.colors[$checkedColor]};
    opacity: ${({ $checked }) => +$checked};
    transition: opacity 200ms ease-in;
  }
`;

export default StyledToggle;
