import React, { useEffect, useState } from "react";
import { usePopper } from "react-popper";
import styled, { css } from "styled-components";
import { Box, Flex } from "../../../../components/Box";
import { UserMenuProps } from "./types";
import { Button } from "../../../../components";
import { UserMenuItem } from "./styles";

export const StyledUserMenu = styled(Button)<{ styleVariant?: string }>`
  ${({ styleVariant }) => styleVariant === "offcanvas" && `width:100%;`}
`;

export const LabelText = styled.div`
  color: ${({ theme }) => theme.colors.text};
  font-weight: 600;
`;

const Menu = styled.div<{ placement?: string; isOpen: boolean }>`
  width: 280px;
  visibility: visible;
  z-index: 1001;
  pointer-events: auto;

  ${({ isOpen }) =>
    !isOpen &&
    css`
      pointer-events: none;
      visibility: hidden;
    `};

  ${({ placement }) =>
    placement === "top"
      ? css`
          padding-bottom: 8px;
        `
      : css`
          padding-top: 8px;
        `}
`;

const MenuContent = styled(Box)<{ placement?: string }>`
  background-color: ${({ theme }) => theme.colors.gray900};
  border: 1px solid ${({ theme }) => theme.colors.gray500};
  border-radius: 8px;
  width: 100%;
  position: relative;

  ${UserMenuItem}:first-child {
    border-radius: 4px 4px 0 0;
  }

  ${UserMenuItem}:last-child {
    border-radius: 0 0 4px 4px;
  }

  &::before {
    content: "";
    position: absolute;
    width: 8px;
    height: 4px;

    ${({ placement, theme }) =>
      placement === "top"
        ? css`
            bottom: -4px;
            left: 50%;
            transform: translateX(-50%);
            border-right: 6px solid transparent;
            border-left: 6px solid transparent;
            border-top: 4px solid ${theme.colors.gray390};
          `
        : css`
            top: -4px;
            right: 16px;
            border-right: 6px solid transparent;
            border-left: 6px solid transparent;
            border-bottom: 4px solid ${theme.colors.gray390};
          `}
  }
`;

const UserMenu: React.FC<UserMenuProps> = ({
  account,
  text,
  variant,
  children,
  disabled,
  placement = "bottom-end",
  recalculatePopover,
  ellipsis = true,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [targetRef, setTargetRef] = useState<HTMLDivElement | null>(null);
  const [tooltipRef, setTooltipRef] = useState<HTMLDivElement | null>(null);

  const { styles, attributes, update } = usePopper(targetRef, tooltipRef, {
    strategy: "absolute",
    placement,
    modifiers: [{ name: "offset", options: { offset: [0, 0] } }],
  });

  const accountEllipsis = account ? `${account.substring(0, 5)}...${account.substring(account.length - 4)}` : null;

  // recalculate the popover position
  useEffect(() => {
    if (recalculatePopover && isOpen && update) update();
  }, [isOpen, update, recalculatePopover]);

  useEffect(() => {
    const showDropdownMenu = () => {
      setIsOpen(true);
    };

    const hideDropdownMenu = (evt: MouseEvent | TouchEvent) => {
      const target = evt.target as Node;
      if (target && !tooltipRef?.contains(target)) {
        setIsOpen(false);
        evt.stopPropagation();
      }
    };

    targetRef?.addEventListener("mouseenter", showDropdownMenu);
    targetRef?.addEventListener("mouseleave", hideDropdownMenu);

    return () => {
      targetRef?.removeEventListener("mouseenter", showDropdownMenu);
      targetRef?.removeEventListener("mouseleave", hideDropdownMenu);
    };
  }, [targetRef, tooltipRef, setIsOpen]);

  return (
    <Flex alignItems="center" height="100%" ref={setTargetRef} {...props}>
      <StyledUserMenu
        onTouchStart={() => {
          setIsOpen((s) => !s);
        }}
        scale="lg"
        variant="outline-primary"
        styleVariant={variant}
      >
        <LabelText title={typeof text === "string" ? text || account : account}>
          {text || (ellipsis ? accountEllipsis : account)}
        </LabelText>
      </StyledUserMenu>
      {!disabled && (
        <Menu placement={placement} style={styles.popper} ref={setTooltipRef} {...attributes.popper} isOpen={isOpen}>
          <MenuContent placement={placement} onClick={() => setIsOpen(false)}>
            {children?.({ isOpen })}
          </MenuContent>
        </Menu>
      )}
    </Flex>
  );
};

export default UserMenu;
