import React from 'react'
import { MessageProps } from './types'
import { StyledMessage } from './StyledMessage'

const Message: React.FC<React.PropsWithChildren<MessageProps>> = ({ children, ...props }) => (
  <StyledMessage {...props}>{children}</StyledMessage>
)

/* eslint-disable react/default-props-match-prop-types */
Message.defaultProps = {
  variant: 'warning',
}

export default Message
