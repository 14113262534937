import styled from "styled-components";
import Button from "../Button/Button";

const MenuButton = styled(Button)`
  color: ${({ theme, $isActive }) => ($isActive ? theme.colors.orange : theme.colors.white)};
  padding: 14px 15px;

  &:not(:last-child) {
    &::before {
      content: "";
      height: 1px;
      position: absolute;
      top: 100%;
      left: 1px;
      right: 1px;
      background: ${({ theme }) => theme.colors.gray500};
    }
  }
`;
MenuButton.defaultProps = {
  variant: "text",
  size: "sm",
  $isActive: false,
};

export default MenuButton;
