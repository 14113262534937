import React, { useContext } from "react";
import styled from "styled-components";
import Flex from "../../../components/Box/Flex";
import { LogoIcon } from "../../../components/Svg";
import { MenuContext } from "../context";

interface Props {
  href: string;
}

const StyledLink = styled("a")`
  display: flex;
  .desktop-icon {
    width: 96px;
  }
`;

const Logo: React.FC<React.PropsWithChildren<Props>> = ({ href }) => {
  const { linkComponent } = useContext(MenuContext);
  const isAbsoluteUrl = href.startsWith("http");

  const linkProps = isAbsoluteUrl ? { as: "a" } : { as: linkComponent };

  return (
    <Flex alignItems="center">
      {React.createElement(
        StyledLink,
        { ...linkProps, href, ariaLabel: "DXcad home page" },
        <LogoIcon className="desktop-icon" />
      )}
    </Flex>
  );
};

export default React.memo(Logo);
