import { CogIcon, useModal, Button, IconButton } from '@pancakeswap/uikit'
import styled from 'styled-components'
import SettingsModal from './SettingsModal'

type Props = {
  mode?: string
  variant?: string
  size?: number
}

const StyledButton = styled(Button)<{ $type?: string }>`
  ${({ $type, theme }) =>
    $type === 'offcanvas' &&
    `
      padding: 12px 0;
      height: auto;
      background: none;
      border: none;
      color: ${theme.colors.white};
      font-size: 24px;
      font-weight: 500;
      line-height: 1.33;
      transition: color 150ms ease-in-out;

      &:hover {
        color: ${theme.colors.gray200};
      }
    `}
`

const GlobalSettings = ({ mode, variant, size = 24, ...props }: Props) => {
  const [onPresentSettingsModal] = useModal(<SettingsModal mode={mode} />)

  return variant === 'offcanvas' ? (
    <StyledButton $type={variant} onClick={onPresentSettingsModal} id={`open-settings-dialog-button-${mode}`}>
      Settings
    </StyledButton>
  ) : (
    <IconButton
      scale="xs"
      variant="text"
      onClick={onPresentSettingsModal}
      id={`open-settings-dialog-button-${mode}`}
      {...props}
    >
      <CogIcon height={size} width={size} />
    </IconButton>
  )
}

export default GlobalSettings
