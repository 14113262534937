import React, { MouseEvent } from "react";
import styled from "styled-components";
import Flex from "../../components/Box/Flex";
import { MotionBox } from "../../components/Box";
import { ArrowBackIcon, CloseIcon } from "../../components/Svg";
import { IconButton } from "../../components/Button";
import { ModalProps } from "./types";
import { Text } from "../../components";

export const mobileFooterHeight = 73;

export const StyledHeading = styled(Text)`
  color: ${({ theme }) => theme.colors.gray100};
  font-size: 24px;
  font-weight: 500;
  line-height: 1.333;
`;

export const ModalHeader = styled.div`
  align-items: center;
  background: transparent;
  border-bottom: 1px solid ${({ theme }) => theme.modal.borderColor};
  display: flex;
  padding: 15px 23px;
`;

export const ModalTitle = styled(Flex)`
  align-items: center;
  flex: 1;
`;

export const ModalBody = styled(Flex)`
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(70vh - ${mobileFooterHeight}px);

  ${({ theme }) => theme.mediaQueries.md} {
    display: flex;
    max-height: 70vh;
  }
`;

export const ModalCloseButton: React.FC<React.PropsWithChildren<{ onDismiss: ModalProps["onDismiss"] }>> = ({
  onDismiss,
}) => {
  return (
    <IconButton
      variant="text"
      scale="sm"
      onClick={(e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        onDismiss?.();
      }}
      aria-label="Close the dialog"
    >
      <CloseIcon color="gray100" width={24} height={24} />
    </IconButton>
  );
};

export const ModalBackButton: React.FC<React.PropsWithChildren<{ onBack: ModalProps["onBack"] }>> = ({ onBack }) => {
  return (
    <IconButton variant="text" scale="sm" onClick={onBack} area-label="go back" mr="4px">
      <ArrowBackIcon color="gray100" width={24} height={24} />
    </IconButton>
  );
};

export const ModalContainer = styled(MotionBox)`
  overflow: hidden;
  background: ${({ theme }) => theme.modal.background};
  box-shadow: 0px 20px 36px -8px rgba(14, 14, 44, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.05);
  border: 1px solid ${({ theme }) => theme.modal.borderColor};
  border-radius: 16px 16px 0px 0px;
  width: 100%;
  max-height: calc(var(--vh, 1vh) * 100);
  z-index: ${({ theme }) => theme.zIndices.modal};
  position: absolute;
  bottom: 0;
  min-height: 300px;

  ${({ theme }) => theme.mediaQueries.md} {
    width: auto;
    position: auto;
    bottom: auto;
    border-radius: 16px;
    max-height: 100vh;
    min-width: 420px;
  }
`;
