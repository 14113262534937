import { parseEther } from 'viem'
import { ButtonMenu, ButtonMenuItem, InjectedModalProps, Modal } from '@pancakeswap/uikit'
import { useAccount, useBalance } from 'wagmi'
import { useState, useCallback } from 'react'
import { useTranslation } from '@pancakeswap/localization'
import styled from 'styled-components'
import WalletInfo from './WalletInfo'
import WalletTransactions from './WalletTransactions'
import WalletWrongNetwork from './WalletWrongNetwork'

export enum WalletView {
  WALLET_INFO,
  TRANSACTIONS,
  WRONG_NETWORK,
}

interface WalletModalProps extends InjectedModalProps {
  initialView?: WalletView
}

export const LOW_NATIVE_BALANCE = parseEther('0.002', 'wei')

const Tabs = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray800};
  padding: 4px;
  border-radius: 12px;
  margin-bottom: 16px;
`

interface TabsComponentProps {
  view: WalletView
  handleClick: (newIndex: number) => void
}

const TabsComponent: React.FC<React.PropsWithChildren<TabsComponentProps>> = ({ view, handleClick }) => {
  const { t } = useTranslation()

  return (
    <Tabs>
      <ButtonMenu variantDefault="transparent" scale="sm" onItemClick={handleClick} activeIndex={view} fullWidth>
        <ButtonMenuItem style={{ borderRadius: '8px', padding: '8px 16px', lineHeight: 1 }}>
          {t('Wallet')}
        </ButtonMenuItem>
        <ButtonMenuItem style={{ borderRadius: '8px', padding: '8px 16px', lineHeight: 1 }}>
          {t('Transactions')}
        </ButtonMenuItem>
      </ButtonMenu>
    </Tabs>
  )
}

const WalletModal: React.FC<React.PropsWithChildren<WalletModalProps>> = ({
  initialView = WalletView.WALLET_INFO,
  onDismiss,
}) => {
  const [view, setView] = useState(initialView)
  const { t } = useTranslation()
  const { address: account } = useAccount()
  const { data, isFetched } = useBalance({ address: account })
  const hasLowNativeBalance = isFetched && data && data.value <= LOW_NATIVE_BALANCE

  const handleClick = useCallback((newIndex: number) => {
    setView(newIndex)
  }, [])

  return (
    <Modal onDismiss={onDismiss} title={t('Your Wallet')} minWidth="500px">
      {view !== WalletView.WRONG_NETWORK && <TabsComponent view={view} handleClick={handleClick} />}

      {view === WalletView.WALLET_INFO && (
        <WalletInfo hasLowNativeBalance={hasLowNativeBalance} switchView={handleClick} onDismiss={onDismiss} />
      )}

      {view === WalletView.TRANSACTIONS && <WalletTransactions onDismiss={onDismiss} />}

      {view === WalletView.WRONG_NETWORK && <WalletWrongNetwork onDismiss={onDismiss} />}
    </Modal>
  )
}

export default WalletModal
