import styled, { DefaultTheme, css } from "styled-components";
import { space, layout, variant } from "styled-system";
import { scaleVariants, styleVariants } from "./theme";
import { BaseButtonProps } from "./types";

interface ThemedButtonProps extends BaseButtonProps {
  theme: DefaultTheme;
}

interface TransientButtonProps extends ThemedButtonProps {
  $isLoading?: boolean;
  variant?: string;
}

const getDisabledStyles = ({ $isLoading, theme, variant: v }: TransientButtonProps) => {
  if ($isLoading === true) {
    return `
      &:disabled,
      &.pancake-button--disabled {
        cursor: not-allowed;
      }
    `;
  }

  if (v === "outline-primary" || v === "gray")
    return `
      &:disabled,
        &.pancake-button--disabled {
        opacity:0.4;
        cursor: not-allowed;
        box-shadow: none;
        }
      `;

  if (v === "primary" || v === "secondary")
    return `
      &:disabled,
        &.pancake-button--disabled {
        box-shadow: none;
        }
      `;

  return `
    &:disabled,
    &.pancake-button--disabled {
      background-color: ${theme.colors.backgroundDisabled};
      border-color: ${theme.colors.backgroundDisabled};
      box-shadow: none;
      color: ${theme.colors.textDisabled};
      cursor: not-allowed;
    }
  `;
};

/**
 * This is to get around an issue where if you use a Link component
 * React will throw a invalid DOM attribute error
 * @see https://github.com/styled-components/styled-components/issues/135
 */

const getOpacity = ({ $isLoading = false }: TransientButtonProps) => {
  return $isLoading ? ".5" : "1";
};

const StyledButton = styled.button<BaseButtonProps>`
  position: relative;
  align-items: center;
  border: ${({ border }) => border ?? "0"};
  border-radius: 100px;
  box-shadow: 0px -1px 0px 0px rgba(14, 14, 44, 0.4) inset;
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  font-size: 15px;
  font-weight: ${({ fontWeight }) => fontWeight ?? 400};
  justify-content: center;
  letter-spacing: 0.03em;
  padding: 16px 32px;
  line-height: 1.5;
  opacity: ${getOpacity};
  outline: 0;

  :focus-visible {
    outline: none;
    box-shadow: ${({ theme }) => theme.shadows.focus};
  }

  ${({ variant: v }) =>
    v?.includes("outline") &&
    css`
      overflow: hidden;
      z-index: 1;
      background: transparent;

      &::after,
      &::before {
        content: "";
        position: absolute;
        inset: 0;
        pointer-events: none;
        border-radius: inherit;
      }

      &::before {
        padding: 2px;
        mask: linear-gradient(#fff 0, #fff 100%) content-box, linear-gradient(#fff 0, #fff 100%) border-box;
        -webkit-mask-composite: xor;
        mask-composite: exclude;
      }

      &::after {
        z-index: -1;
      }
    `}

  ${({ variant: v, theme }) => css`
    ${v === "outline-primary" &&
    css`
      &::before,
      &::after {
        background: ${theme.colors.gradientPrimary};
      }

      &::after {
        transition: opacity 0.2s;
        opacity: 0.2;
      }

      &:hover:not(:disabled):not(.pancake-button--disabled):not(.pancake-button--disabled) {
        &::after {
          opacity: 0.1;
        }
      }
    `}

    ${v === "outline-secondary" &&
    css`
      &::before,
      &::after {
        background: ${theme.colors.gray500};
      }

      &::after {
        transition: opacity 0.2s;
        opacity: 0;
      }

      &:hover:not(:disabled):not(.pancake-button--disabled):not(.pancake-button--disabled) {
        &::after {
          opacity: 0.2;
        }
      }
    `}

    ${v === "outline-light" &&
    css`
      &::before,
      &::after {
        background: ${theme.colors.white200};
      }

      &::after {
        transition: opacity 0.2s;
        opacity: 0;
      }

      &:hover:not(:disabled):not(.pancake-button--disabled):not(.pancake-button--disabled) {
        &::after {
          opacity: 0.2;
        }
      }
    `}


    ${v === "underlined-text" &&
    css`
      color: ${theme.colors.gray100};
      font-weight: 500;
      font-size: 14px;
      line-height: 1.28;
      letter-spacing: 0.4px;
      text-decoration: underline;
      background: transparent;
      justify-content: start;
      box-shadow: none;
    `}
  `}
  

  ${getDisabledStyles}
  ${variant({
    prop: "scale",
    variants: scaleVariants,
  })}
  ${variant({
    variants: styleVariants,
  })}
  ${layout}
  ${space}
  ${({ decorator, theme }) =>
    decorator &&
    css`
      &::before {
        content: "${decorator.text}";
        position: absolute;
        border-bottom: 20px solid ${decorator.backgroundColor ?? theme.colors.secondary};
        border-left: 34px solid transparent;
        border-right: 12px solid transparent;
        height: 0;
        top: -1px;
        right: -12px;
        width: 75px;
        text-align: center;
        padding-right: 30px;
        line-height: 20px;
        font-size: 12px;
        font-weight: 400;
        transform: rotate(31.17deg);
        color: ${decorator.color ?? "white"};
      }
    `}
`;

export default StyledButton;
