import { Button, Input, Box, Flex } from '@pancakeswap/uikit'
import styled from 'styled-components'

// GasSettings.tsx
export const StyledButton = styled(Button)`
  font-weight: 500;
`

// SettingsModal
export const ScrollableContainer = styled(Flex)`
  flex-direction: column;
  height: auto;
  ${({ theme }) => theme.mediaQueries.xs} {
    max-height: 90vh;
  }
  ${({ theme }) => theme.mediaQueries.md} {
    max-height: none;
  }
`

export const StyledLabel = styled.label<{ mb?: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% + 16px);
  padding: 6px 8px;
  border-radius: 8px;
  margin: 0 -8px ${({ mb }) => mb || 0};
  transition: background-color 250ms;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray800};
  }
`

// Transaction Settings
export const InputWrap = styled(Box)`
  position: relative;

  &::after {
    content: attr(data-text);
    right: 16px;
    top: 3px;
    font-weight: 500;
    line-height: 1.5;
    z-index: 1;
    color: ${({ theme }) => theme.colors.gray200};
    position: absolute;
    pointer-events: none;
  }
`

export const ButtonSlippage = styled(Button)`
  font-size: 16px;
  font-weight: 500;
`

export const InputWrapSlippage = styled(InputWrap)<{ isChecked?: boolean }>`
  &::after {
    top: 3px;
    color: ${({ theme, isChecked }) => (isChecked ? theme.colors.white500 : theme.colors.gray200)};
  }
`

export const StyledInputDeadline = styled(Input)`
  padding-right: 52px;
  border-radius: 16px;
  text-align: center;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray100};
  background-color: ${({ theme }) => theme.colors.gray800};

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray200};
    opacity: 1;
  }

  &:hover {
    border-color: ${({ theme }) => theme.colors.gray300};
    background-color: ${({ theme }) => theme.colors.gray500};
    box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.05);
  }

  &:focus:not(:disabled) {
    box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.05);
    color: ${({ theme }) => theme.colors.gray100};
  }
`

export const StyledInputSlippage = styled(StyledInputDeadline)<{ isChecked: boolean }>`
  padding-right: 34px;
  padding-left: 12px;
  color: ${({ theme }) => theme.colors.gray200};
  border-color: ${({ theme }) => theme.colors.gray500};
  cursor: pointer;

  ${({ theme, isChecked }) =>
    isChecked &&
    `
    color: ${theme.colors.gray100};
    border-width: 0;
    box-shadow: none;
    background: ${theme.colors.gradientPrimary};

    &::placeholder {
      color: ${theme.colors.gray100};
    }
  `}
`
