import React, { ReactElement, useMemo } from "react";
import { ChainId } from "@pancakeswap/sdk";
import Link from "./Link";
import OpenNewIcon from "../Svg/Icons/OpenNew";
import BscScanIcon from "../Svg/Icons/BscScan";
import { LinkProps } from "./types";

interface ScanLinkProps extends Omit<LinkProps, "external" | "showExternalIcon"> {
  icon?: ReactElement;
  chainId?: ChainId;
  isWithoutIcon?: boolean;
  iconSize?: number;
}

const ScanLink: React.FC<React.PropsWithChildren<ScanLinkProps>> = ({
  children,
  icon,
  chainId,
  isWithoutIcon = false,
  iconSize,
  ...props
}) => {
  const iconToShow = useMemo(() => {
    if (icon) return icon;
    if (chainId && chainId === ChainId.BSC) {
      return <BscScanIcon width={iconSize} height={iconSize} />;
    }
    if (chainId && chainId === ChainId.BSC_TESTNET) {
      return <BscScanIcon width={iconSize} height={iconSize} />;
    }
    return <OpenNewIcon width={iconSize} height={iconSize} />;
  }, [icon, chainId]);

  const iconElement = useMemo(() => {
    return React.isValidElement(iconToShow)
      ? React.cloneElement(iconToShow, {
          // @ts-ignore
          color: props.color ? props.color : "primary",
          ml: "4px",
        })
      : null;
  }, [iconToShow, props.color]);
  return (
    <Link external {...props}>
      {children}
      {!isWithoutIcon && iconElement}
    </Link>
  );
};

export default ScanLink;
