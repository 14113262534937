import styled, { css } from "styled-components";
import { StyledMenuItemProps } from "./types";

export const StyledMenuItemContainer = styled.div<StyledMenuItemProps>`
  position: relative;

  ${({ $isActive, $variant, theme }) =>
    $isActive &&
    $variant === "subMenu" &&
    `
      &:after{
        content: "";
        position: absolute;
        bottom: 0;
        height: 4px;
        width: 100%;
        background-color: ${theme.colors.primary};
        border-radius: 2px 2px 0 0;
      }
    `};

  ${({ $variant }) =>
    $variant !== "offcanvas" &&
    css`
      margin: 0 24px;
    `}
`;

const StyledMenuItem = styled.a<StyledMenuItemProps>`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 8px;
  color: ${({ theme, $isActive }) => ($isActive ? theme.colors.orange : theme.colors.white)};
  font-size: 16px;
  line-height: 1.75;
  opacity: ${({ $isDisabled }) => ($isDisabled ? 0.5 : 1)};
  pointer-events: ${({ $isDisabled }) => ($isDisabled ? "none" : "inherit")};
  transition: color 0.15s ease-in-out;

  ${({ $statusColor, theme }) =>
    $statusColor &&
    `
    &:after {
      content: "";
      border-radius: 100%;
      background: ${theme.colors[$statusColor]};
      height: 8px;
      width: 8px;
      margin-left: 12px;
    }
  `}

  &:hover, &:focus-visible {
    color: ${({ theme, $isActive }) => !$isActive && theme.colors.gray200};
  }

  ${({ $variant }) =>
    $variant === "offcanvas" &&
    css`
      font-size: 24px;
      font-weight: 500;
      line-height: 1.33;
      padding: 12px 0;
    `}
`;

export default StyledMenuItem;
