import { useTranslation } from '@pancakeswap/localization'
import {
  Flex,
  LogoutIcon,
  RefreshIcon,
  useModal,
  UserMenu as UIKitUserMenu,
  UserMenuItem,
  Placement,
  Box,
} from '@pancakeswap/uikit'
import ConnectWalletButton from 'components/ConnectWalletButton'
import Trans from 'components/Trans'
import { NetworkSwitcher } from 'components/NetworkSwitcher'
import { useActiveChainId } from 'hooks/useActiveChainId'
import useAuth from 'hooks/useAuth'
import { useEffect, useState, useCallback } from 'react'
import { usePendingTransactions } from 'state/transactions/hooks'
import { useAccount } from 'wagmi'
import { useDomainNameForAddress } from 'hooks/useDomain'
import WalletModal, { WalletView } from './WalletModal'
import WalletUserMenuItem from './WalletUserMenuItem'

interface UserMenuProps {
  placement?: Placement
  variant?: string
  recalculatePopover?: boolean
}

const UserMenuItems = () => {
  const { t } = useTranslation()
  const { isWrongNetwork } = useActiveChainId()
  const { logout } = useAuth()
  const { hasPendingTransactions } = usePendingTransactions()

  const [onPresentWalletModal] = useModal(<WalletModal initialView={WalletView.WALLET_INFO} />)
  const [onPresentTransactionModal] = useModal(<WalletModal initialView={WalletView.TRANSACTIONS} />)
  const [onPresentWrongNetworkModal] = useModal(<WalletModal initialView={WalletView.WRONG_NETWORK} />)

  const onClickWalletMenu = useCallback((): void => {
    if (isWrongNetwork) {
      onPresentWrongNetworkModal()
    } else {
      onPresentWalletModal()
    }
  }, [isWrongNetwork, onPresentWalletModal, onPresentWrongNetworkModal])

  return (
    <>
      <WalletUserMenuItem isWrongNetwork={isWrongNetwork} onPresentWalletModal={onClickWalletMenu} />
      <UserMenuItem as="button" disabled={isWrongNetwork} onClick={onPresentTransactionModal}>
        {t('Recent Transactions')}
        {hasPendingTransactions && <RefreshIcon spin />}
      </UserMenuItem>
      <UserMenuItem as="button" onClick={logout}>
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          {t('Disconnect')}
          <LogoutIcon />
        </Flex>
      </UserMenuItem>
    </>
  )
}

const UserMenu: React.FC<UserMenuProps> = ({ placement, variant, recalculatePopover }) => {
  const { t } = useTranslation()
  const { address: account } = useAccount()
  const { domainName, avatar } = useDomainNameForAddress(account)
  const { isWrongNetwork } = useActiveChainId()
  const { hasPendingTransactions, pendingNumber } = usePendingTransactions()
  const avatarSrc = avatar
  const [userMenuText, setUserMenuText] = useState<string>('')

  useEffect(() => {
    if (hasPendingTransactions) {
      setUserMenuText(t('%num% Pending', { num: pendingNumber }))
    } else {
      setUserMenuText('')
    }
  }, [hasPendingTransactions, pendingNumber, t])

  if (account) {
    return (
      <Box style={{ display: 'flex', flexWrap: 'wrap', height: 'fit-content', justifyContent: 'center' }}>
        <NetworkSwitcher />
        <UIKitUserMenu
          account={domainName || account}
          ellipsis={!domainName}
          avatarSrc={avatarSrc}
          text={userMenuText}
          variant={variant}
          placement={placement}
          recalculatePopover={recalculatePopover}
        >
          {({ isOpen }) => (isOpen ? <UserMenuItems /> : null)}
        </UIKitUserMenu>
      </Box>
    )
  }

  if (isWrongNetwork) {
    return (
      <UIKitUserMenu text={t('Network')} variant={variant} recalculatePopover={recalculatePopover}>
        {({ isOpen }) => (isOpen ? <UserMenuItems /> : null)}
      </UIKitUserMenu>
    )
  }

  return (
    <Box style={{ display: 'flex', flexWrap: 'wrap', height: 'fit-content', justifyContent: 'center' }}>
      <NetworkSwitcher />
      <ConnectWalletButton>
        <Trans>Connect Wallet</Trans>
      </ConnectWalletButton>
    </Box>
  )
}

export default UserMenu
