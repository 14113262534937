import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = ({ color = "#7322A6", strokeWidth = 1.5, ...props }) => (
  <Svg viewBox="0 0 24 24" color={color} {...props}>
    <path
      fill="none"
      d="M12 12L21 3M21 3V10M21 3H14M9 3H5C3.895 3 3 3.895 3 5V19C3 20.105 3.895 21 5 21H19C20.105 21 21 20.105 21 19V15"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Icon;
