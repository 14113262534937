import React from "react";
import styled from "styled-components";
import LogoRound from "../Svg/Icons/LogoRound";
import Text from "../Text/Text";
import Skeleton from "../Skeleton/Skeleton";
import { Flex } from "../Box";

export interface Props {
  xcadPriceUsd?: string;
  showSkeleton?: boolean;
}

const PriceWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.white50};
  border-radius: 40px;
  align-items: center;
  column-gap: 6px;

  ${({ theme }) => theme.mediaQueries.md} {
    margin-top: 20px;
  }

  ${({ theme }) => theme.mediaQueries.bdMd} {
    order: -1;
    margin-bottom: 24px;
  }
`;

const XcadPrice: React.FC<React.PropsWithChildren<Props>> = ({ xcadPriceUsd, showSkeleton = true }) => {
  return xcadPriceUsd ? (
    <PriceWrapper px="12px" py="8px">
      <Text color="white300" fontSize="13px" lineHeight="1.23">
        Price:
      </Text>
      <Flex>
        <LogoRound width="20px" mr="4px" />
        <Text fontSize="16px" lineHeight="1.75">
          {xcadPriceUsd ?? "–.–––"}
        </Text>
      </Flex>
    </PriceWrapper>
  ) : showSkeleton ? (
    <Skeleton width={80} height={24} />
  ) : null;
};

export default React.memo(XcadPrice);
