import { variants } from './types'

export const styleVariants: { [key: string]: { backgroundColor?: string; color?: string } } = {
  [variants.FAILURE]: {
    backgroundColor: 'red100',
    color: 'failure',
  },
  [variants.WARNING]: {
    backgroundColor: 'yellow100',
    color: 'warning',
  },
}
