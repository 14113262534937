import styled, { css } from "styled-components";

const StyledSpotBase = styled.div`
  position: absolute;
  content: "";
  width: 272px;
  height: 980px;
  transform: rotate(-45deg) translateZ(0);
  border-radius: 1200px;
  background: ${({ theme }) => theme.colors.gradientPrimary};
  opacity: 0.56;
  filter: blur(111.5px);
  /* z-index: 0; */

  @-moz-document url-prefix() {
    opacity: 0.35;
  }
`;
interface StyledSpotProps {
  variant?: "left" | "right";
}

const BackgroundGradient = styled(StyledSpotBase).attrs((props: StyledSpotProps) => ({
  variant: props.variant || "left",
}))`
  ${({ variant: v }) =>
    v?.includes("left") &&
    css`
      bottom: 0;
      left: -20px;
    `}

  ${({ variant: v }) =>
    v?.includes("right") &&
    css`
      top: -450px;
      right: -140px;
    `}
`;

export default BackgroundGradient;
