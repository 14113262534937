import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg
      stroke="#FFA337"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      viewBox="0 0 20 20"
      {...props}
    >
      <path fill="none" d="M10 11V7.7m0 5.7-.2.2a.2.2 0 1 0 .2-.2" />
      <path
        fill="none"
        d="M11.7 3.4 18 14.6a2 2 0 0 1-1.7 3H3.6a2 2 0 0 1-1.7-3L8.3 3.4a2 2 0 0 1 3.4 0Z"
        clipRule="evenodd"
      />
    </Svg>
  );
};

export default Icon;
