import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" {...props}>
      <rect width="14" height="14" x="7" y="7" stroke="currentColor" fill="none" rx="2" />
      <path stroke="currentColor" fill="none" d="M7 17H5a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2h10a2 2 0 0 1 2 2v2" />
    </Svg>
  );
};

export default Icon;
