import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | dXCAD DEX',
  defaultTitle: 'dXCAD DEX',
  description:
    'Cheap and fast trading for Creator Tokens, XCAD and PLAY. Discover dXCAD, the leading BNB Chain (BSC) DEX for farming yield and earning crypto.',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@XcademyOfficial',
    site: '@XcademyOfficial',
  },
  openGraph: {
    title: 'dXCAD DEX - A next evolution DeFi exchange on BNB Smart Chain (BSC)',
    description: '',
    images: [{ url: `${process.env.NEXT_PUBLIC_VERCEL_URL}/images/hero.png` }],
  },
}
