import BigNumber from 'bignumber.js'
import { ChainId } from '@pancakeswap/sdk'
import { Address } from 'viem'

import fromPairs from 'lodash/fromPairs'
import { OnChainProvider, SerializedLockedVaultUser, SerializedPool, SerializedVaultUser, VaultKey } from '../types'
import { getXcadFlexibleSideVaultAddress } from './getAddresses'
import { xcadPoolAbi } from '../abis/XcadPoolAbi'
import { xcadLockedPoolAbi } from '../abis/XcadLockedPoolAbi'
import { base10KToBase10, getTimestampForDay } from '../utils/lockedPoolUtils'
import { getPoolsConfig } from '../constants'

interface Params {
  account: Address
  lockedPools?: Array<SerializedPool>
  chainId: ChainId
  provider: OnChainProvider
}

export const fetchVaultUserData = async ({ chainId, provider, account, lockedPools }: Params) => {
  const client = provider({ chainId })

  const userData = await client.multicall({
    contracts: lockedPools?.map(({ lockedAddress }) => ({
      address: lockedAddress,
      abi: xcadLockedPoolAbi,
      functionName: 'getUserInfo',
      args: [account],
    })) as any[],
    allowFailure: true,
  })

  return fromPairs(
    lockedPools?.map((pool, index) => {
      const res: any = userData[index].result
      const pools = res[0]
      const currentPool = pools[pool.lockedPoolId || 0]

      const feePercent = base10KToBase10(currentPool.pool.withdrawalFee)

      return [
        pool.sousId,
        userData[index].status === 'success' && res
          ? {
              currentOverdueFee: new BigNumber(feePercent.toString()).toJSON(),
              userDeposit: new BigNumber(currentPool.userInfo.userDeposit.toString()).toJSON(),
              accrueInterest: new BigNumber(currentPool.userInfo.accrueInterest.toString()).toJSON(),
              lastDayAction: currentPool.userInfo.lastDayAction,
              lastDayActionCount: currentPool.userInfo.lastDayAction,
              hasDeposited: !!currentPool.userInfo.userDeposit,
              hasRewards: !!currentPool.userInfo.accrueInterest,
              isLocked: !!currentPool.userInfo.userDeposit && !currentPool.userInfo.endLockTime,
              endLockDate: currentPool.timestampEndLockPeriod,
              endDay: currentPool.pool.endDay,
            }
          : {
              currentOverdueFee: null,
              userDeposit: null,
              accrueInterest: null,
              lastDayAction: null,
              lastDayActionCount: null,
              hasDeposited: null,
              hasRewards: null,
              isLocked: null,
              endLockDate: null,
              endDay: null,
            },
      ]
    }),
  )
}

export const fetchVaultUser = async ({ account, chainId, provider }: Params): Promise<SerializedLockedVaultUser[]> => {
  try {
    // const client = provider({ chainId })
    const poolsConfig = getPoolsConfig(chainId) || []
    const lockedPools = poolsConfig.filter(({ vaultKey }) => vaultKey === VaultKey.CakeVault)
    const userInfo = await fetchVaultUserData({ chainId, account, lockedPools, provider })

    return (
      lockedPools?.map((pool) => {
        const currentPoolInfo: any = userInfo[pool.sousId]
        const lastDepositedTimeMS = getTimestampForDay(currentPoolInfo.lastDayAction)
        return {
          isLoading: false,
          sousId: pool.sousId,
          accrueInterest: new BigNumber(currentPoolInfo.accrueInterest.toString()).toJSON(),
          hasRewards: !!currentPoolInfo.hasRewards,
          userShares: new BigNumber(currentPoolInfo.userDeposit.toString()).toJSON(),
          lastDepositedTime: (lastDepositedTimeMS / 1000).toString(),
          lastUserActionTime: (lastDepositedTimeMS / 1000).toString(),
          lastUserActionCount: currentPoolInfo.lastDayAction.toString(),
          locked: !!currentPoolInfo.isLocked,
          lockEndTime: currentPoolInfo.endLockDate.toString(),
          lockedAmount: new BigNumber(currentPoolInfo.userDeposit.toString()).toJSON(),
          currentOverdueFee: new BigNumber(currentPoolInfo.currentOverdueFee.toString()).toJSON(),
        }
      }) || []
    )
  } catch (error) {
    return []
  }
}

export const fetchFlexibleSideVaultUser = async ({
  account,
  chainId,
  provider,
}: Params): Promise<SerializedVaultUser> => {
  try {
    const client = provider({ chainId })

    const [userBalance, earned, lastDepositedTime] = await client.multicall({
      contracts: [
        {
          abi: xcadPoolAbi,
          address: getXcadFlexibleSideVaultAddress(chainId),
          functionName: 'balanceOf',
          args: [account],
        },
        {
          abi: xcadPoolAbi,
          address: getXcadFlexibleSideVaultAddress(chainId),
          functionName: 'earned',
          args: [account],
        },
        {
          abi: xcadPoolAbi,
          address: getXcadFlexibleSideVaultAddress(chainId),
          functionName: 'lastStakedTime',
          args: [account],
        },
      ],
      allowFailure: false,
    })

    return {
      isLoading: false,
      userShares: new BigNumber(userBalance.toString()).toJSON(),
      earned: new BigNumber(earned.toString()).toJSON(),
      lastDepositedTime: lastDepositedTime.toString(),
    }
  } catch (error) {
    return {
      isLoading: true,
      userShares: '',
      earned: '',
      lastDepositedTime: '',
    }
  }
}
