import React from "react";
import styled from "styled-components";
import EXTERNAL_LINK_PROPS from "../../util/externalLinkProps";
import Text from "../Text/Text";
import { LinkProps } from "./types";

const StyledLink = styled(Text)<LinkProps>`
  display: flex;
  align-items: center;
  width: fit-content;
  transition color 150ms ease-in-out;
  
  &:hover {
    color: ${({ theme }) => theme.colors.gray200};
  }

  ${({ variant, theme }) =>
    variant === "primary-text" &&
    ` 
      background: ${theme.colors.gradientPrimary};
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      transition: filter 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94);

      &:hover {
        filter: hue-rotate(360deg);
      }
    `}

  ${({ variant, theme }) =>
    variant === "underlined-text" &&
    `
      color: ${theme.colors.gray100};
      font-weight: 500;
      font-size: 14px;
      line-height: 1.28;
      letter-spacing: 0.4px;
      text-decoration: underline;
    `}
`;

const Link: React.FC<React.PropsWithChildren<LinkProps>> = ({ external, ...props }) => {
  const internalProps = external ? EXTERNAL_LINK_PROPS : {};
  return <StyledLink as="a" medium {...internalProps} {...props} />;
};

/* eslint-disable react/default-props-match-prop-types */
Link.defaultProps = {
  color: "primary",
};

export default Link;
