import styled from "styled-components";
import { PolymorphicComponent } from "../../util/polymorphic";
import Button from "./Button";
import { BaseButtonProps } from "./types";

const IconButton: PolymorphicComponent<BaseButtonProps, "button"> = styled(Button)<BaseButtonProps>`
  padding: ${({ scale }) => (scale === "sm" ? "0" : "14px")};
  width: ${({ scale }) => (scale === "xs" ? "auto" : scale === "sm" ? "24px" : "48px")};
  height: auto;

  transition: opacity 150ms ease-in-out;

  &:hover,
  &:focus-visible {
    opacity: 0.6;
  }
`;

export default IconButton;
