import styled from "styled-components";
import { UserMenuItemProps } from "./types";

export const UserMenuDivider = styled.hr`
  border-color: ${({ theme }) => theme.colors.cardBorder};
  border-style: solid;
  border-width: 1px 0 0;
  margin: 4px 0;
`;

export const UserMenuItem = styled.button<UserMenuItemProps>`
  align-items: center;
  border: 0;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.gray100};
  cursor: pointer;
  display: flex;
  font-size: 16px;
  column-gap: 8px;
  justify-content: space-between;
  outline: 0;
  padding: 14px 16px;
  width: 100%;
  line-height: 1.75;

  transition: background-color 150ms ease-in-out, color 150ms ease-in-out;

  &:is(button) {
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  }

  &:hover:not(:disabled) {
    background-color: ${({ theme }) => theme.colors.gray800};
  }

  &:active:not(:disabled) {
    color: ${({ theme }) => theme.colors.orange};
  }

  &:not(:last-child) {
    position: relative;
    margin-bottom: 1px;
    &::before {
      content: "";
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 1px;
      background: ${({ theme }) => theme.colors.gray500};
    }
  }
`;
