import { bscTestnetTokens } from '@pancakeswap/tokens'
import { getAddress } from 'viem'
import { SerializedFarmConfig } from '..'
import { defineFarmV3Configs } from '../src/defineFarmV3Configs'

export const farmsV3 = defineFarmV3Configs([])

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 2, 3) should always be at the top of the file.
   */
  // {
  //   pid: 1,
  //   lpSymbol: 'XCAD-CAKE LP',
  //   lpAddress: '0x22b71218bC68d6FD2720e94bcFe3CEa37d5EE619',
  //   stakingRewardsAddress: '0x04AaD33f0939bb1dc72CDBC08945D21e259bC33a',
  //   token: bscTestnetTokens.xcad,
  //   quoteToken: bscTestnetTokens.cake,
  // },
].map((p) => ({
  // @ts-ignore
  ...p,
  // @ts-ignore
  token: p.token.serialize,
  // @ts-ignore
  quoteToken: p.quoteToken.serialize,
  // @ts-ignore
  lpAddress: getAddress(p.lpAddress),
  // @ts-ignore
  stakingRewardsAddress: getAddress(p.stakingRewardsAddress),
}))

export default farms
