import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 16 16" {...props}>
      <path d="M3.32 7.63A.68.68 0 0 1 4 6.95h1.13a.68.68 0 0 1 .68.68v4.28l.47-.12a.57.57 0 0 0 .44-.55v-5.3a.68.68 0 0 1 .68-.69h1.13a.68.68 0 0 1 .68.68v4.93l.56-.23a.57.57 0 0 0 .34-.53V4.24a.68.68 0 0 1 .68-.68h1.13a.68.68 0 0 1 .68.68v4.84c.98-.71 1.97-1.57 2.76-2.6a1.14 1.14 0 0 0 .18-1.07A8.01 8.01 0 0 0 4.67.75a8 8 0 0 0-3.6 11.27 1.01 1.01 0 0 0 .96.5l.8-.08a.56.56 0 0 0 .5-.56V7.63M3.3 14.5a7.98 7.98 0 0 0 12.67-7.04c-2.92 4.37-8.31 6.41-12.67 7.03Z" />
    </Svg>
  );
};

export default Icon;
