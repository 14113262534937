import * as React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path d="M23.7 2.2A.8.8 0 0 0 23 2L.5 10.4a.8.8 0 0 0-.5.8.8.8 0 0 0 .6.8L6 13.7l2.2 6.6a.9.9 0 0 0 1.4.3l3-2.9A672.5 672.5 0 0 0 19 22a1.2 1.2 0 0 0 1.3-1L24 3a.8.8 0 0 0-.3-.8Zm-4 3.8L9.3 15.1 9 18.6l-1.7-5.5 11.9-7.6a.3.3 0 0 1 .3.5Z" />
    </Svg>
  );
};

export default Icon;
