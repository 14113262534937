import * as React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path d="m0 0 10 13.2L0 24h2.2l8.8-9.4 7 9.4h7.7L15.2 10l9.3-10h-2.2l-8 8.7L7.6 0H.1Zm3.4 1.7h3.5l15.5 20.6h-3.5L3.4 1.7Z" />
    </Svg>
  );
};

export default Icon;
