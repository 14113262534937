import React, { ReactNode } from "react";
import styled from "styled-components";
import { MenuItemsType } from "../../../components/MenuItems/types";
import MenuItem from "../../../components/MenuItem/MenuItem";
import { Grid } from "../../../components";

interface NavMenuMobileProps {
  show: boolean;
  settings?: ReactNode;
  userMenu?: ReactNode;
  links: Array<MenuItemsType>;
  activeLink?: string;
  onHide: () => void;
}

const StyledOffcanvas = styled(Grid)`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.background};
  align-content: space-between;
  top: 0;
  left: 0;
  transition-property: margin-top, opacity, visibility;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  opacity: 0;
  visibility: hidden;
  padding-top: 140px;

  &[aria-expanded="true"] {
    margin-top: 0;
    opacity: 1;
    visibility: visible;
  }

  &[aria-expanded="false"] {
    margin-top: -100%;
    pointer-events: none;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    padding-right: 48px;
    padding-left: 48px;
    padding-bottom: 48px;
  }

  ${({ theme }) => theme.mediaQueries.boMd} {
    padding-right: 32px;
    padding-left: 32px;
    padding-bottom: 32px;
  }

  ${({ theme }) => theme.mediaQueries.bdMd} {
    padding-right: 24px;
    padding-left: 24px;
    padding-bottom: 24px;
  }
`;

const StyledList = styled.ul`
  list-style: none;
  margin-top: -12px;
  margin-bottom: -12px;
`;

const NavMenuMobile: React.FC<NavMenuMobileProps> = ({ show, userMenu, settings, links, activeLink, onHide }) => {
  return (
    <StyledOffcanvas aria-expanded={show}>
      <StyledList>
        {links.map(({ label, href, disabled }) => {
          const isActive = activeLink === href;
          return (
            <li key={label}>
              <MenuItem isActive={isActive} isDisabled={disabled} href={href} variant="offcanvas" onClick={onHide}>
                {label}
              </MenuItem>
            </li>
          );
        })}
        <li>{settings}</li>
      </StyledList>

      {userMenu}
    </StyledOffcanvas>
  );
};

export default NavMenuMobile;
