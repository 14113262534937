import {
  BackgroundGradient,
  CustomFontComponent,
  ResetCSS,
  ScrollToTopButtonV2,
  ToastListener,
} from '@pancakeswap/uikit'
import { PageMeta } from 'components/Layout/Page'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor } from 'state'
import { useAccountEventListener } from 'hooks/useAccountEventListener'
import useEagerConnect from 'hooks/useEagerConnect'
import useEagerConnectMP from 'hooks/useEagerConnect.bmp'
import useSentryUser from 'hooks/useSentryUser'
import useThemeCookie from 'hooks/useThemeCookie'
import useUserAgent from 'hooks/useUserAgent'
import { usePollBlockNumber } from 'state/block/hooks'
import { NetworkModal } from 'components/NetworkModal'
import { FixedSubgraphHealthIndicator } from 'components/SubgraphHealthIndicator/FixedSubgraphHealthIndicator'
import { Fragment } from 'react'
import dynamic from 'next/dynamic'
import { NextPage } from 'next'
import { AppProps } from 'next/app'
import styled from 'styled-components'
import GlobalStyle from '../../style/Global'
import { Blocklist, Updaters } from '../..'
import { SentryErrorBoundary } from '../ErrorBoundary'
import Menu from '../Menu'

type NextPageWithLayout = NextPage & {
  Layout?: React.FC<React.PropsWithChildren<unknown>>
  /** render component without all layouts */
  pure?: true
  /** is mini program */
  mp?: boolean
  /**
   * allow chain per page, empty array bypass chain block modal
   * @default [ChainId.BSC]
   * */
  chains?: number[]
  isShowScrollToTopButton?: true
  /**
   * Meta component for page, hacky solution for static build page to avoid `PersistGate` which blocks the page from rendering
   */
  Meta?: React.FC<React.PropsWithChildren<unknown>>
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const ProductionErrorBoundary = process.env.NODE_ENV === 'production' ? SentryErrorBoundary : Fragment

const StyledGradientWrapper = styled.div`
  position: absolute;
  inset: 0;
  overflow: hidden;
  pointer-events: none;
`

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  if (Component.pure) {
    return <Component {...pageProps} />
  }

  // Use the layout defined at the page level, if available
  const Layout = Component.Layout || Fragment
  const ShowMenu = Component.mp ? Fragment : Menu
  const isShowScrollToTopButton = Component.isShowScrollToTopButton || true

  return (
    <>
      <CustomFontComponent />
      <ProductionErrorBoundary>
        <StyledGradientWrapper>
          <BackgroundGradient variant="right" />
          <BackgroundGradient />
        </StyledGradientWrapper>
        <ShowMenu>
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </ShowMenu>
        <ToastListener />
        <FixedSubgraphHealthIndicator />
        <NetworkModal pageSupportedChains={Component.chains} />
        {isShowScrollToTopButton && <ScrollToTopButtonV2 />}
      </ProductionErrorBoundary>
    </>
  )
}

const GlobalHooks = () => {
  usePollBlockNumber()
  useEagerConnect()
  useUserAgent()
  useAccountEventListener()
  useSentryUser()
  useThemeCookie()
  return null
}

const MPGlobalHooks = () => {
  usePollBlockNumber()
  useEagerConnectMP()
  useUserAgent()
  useAccountEventListener()
  useSentryUser()
  return null
}

const AppInner = ({ Component, pageProps, ...props }: AppPropsWithLayout) => {
  return (
    <>
      <PageMeta />
      {(Component as NextPageWithLayout).Meta && (
        // @ts-ignore
        <Component.Meta {...pageProps} />
      )}
      <Blocklist>
        {(Component as NextPageWithLayout).mp ? <MPGlobalHooks /> : <GlobalHooks />}
        <ResetCSS />
        <GlobalStyle />
        <PersistGate loading={null} persistor={persistor}>
          <Updaters />
          <App Component={Component} pageProps={pageProps} {...props} />
        </PersistGate>
      </Blocklist>
    </>
  )
}

export default AppInner
