import { PolymorphicComponent } from "../../util/polymorphic";
import Button from "../Button/Button";
import { variants } from "../Button/types";
import { ButtonMenuItemProps } from "./types";

const ButtonMenuItem: PolymorphicComponent<ButtonMenuItemProps, "button"> = ({
  isActive = false,
  variant = variants.PRIMARY,
  variantDefault = variants.SECONDARY,
  as,
  ...props
}: ButtonMenuItemProps) => {
  return <Button fontWeight={500} as={as} variant={isActive ? variant : variantDefault} {...props} />;
};

export default ButtonMenuItem;
