import React from "react";
import styled from "styled-components";

interface BtnAnimatedBurgerProps {
  isModalOpen: boolean;
  onClick: () => void;
}

export const BTN_BURGER_TITLE = {
  closed: "Open Navigation",
  open: "Close Navigation",
};

const StyledIcon = styled.svg`
  width: 100%;
  height: 100%;
  transition: transform 0.3s;
`;

const StyledLine = styled.path`
  stroke: ${({ theme }) => theme.colors.white};
  stroke-dashoffset: 0;
  stroke-width: 1.25;
  transition: stroke-dashoffset 400ms;
`;

const StyledLineTop = styled(StyledLine)`
  stroke-dasharray: 16 160;
`;

const StyledLineMid = styled(StyledLine)`
  stroke-dasharray: 16 160;
  transition: transform 400ms;
  transform-origin: 50%;
`;

const StyledLineBottom = styled(StyledLine)`
  stroke-dasharray: 16 160;
`;

const StyledBtn = styled.button`
  box-sizing: content-box;
  background: transparent;
  border: none;
  cursor: pointer;
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 8px;
  transition: opacity 0.25s;

  &:hover {
    opacity: 0.8;
  }

  &[aria-expanded="true"] {
    ${StyledIcon} {
      transform: rotate(45deg);
    }

    ${StyledLineTop} {
      stroke-dashoffset: -24;
    }

    ${StyledLineMid} {
      transform: rotate(90deg);
    }

    ${StyledLineBottom} {
      stroke-dashoffset: -24;
    }
  }
`;

const BtnAnimatedBurger: React.FC<BtnAnimatedBurgerProps> = (props) => {
  const { isModalOpen, onClick, ...rest } = props;

  return (
    <StyledBtn
      {...rest}
      type="button"
      onClick={onClick}
      aria-expanded={isModalOpen}
      {...(isModalOpen
        ? {
            "aria-label": BTN_BURGER_TITLE.open,
            title: BTN_BURGER_TITLE.open,
          }
        : {
            "aria-label": BTN_BURGER_TITLE.closed,
            title: BTN_BURGER_TITLE.closed,
          })}
    >
      <StyledIcon fill="none" viewBox="0 0 24 24">
        <StyledLineTop d="M4 8h16 c1 0 3 .4 3 2s-2 2-3 2 h-8" />
        <StyledLineMid d="M4 12 h16" />
        <StyledLineBottom d="M20 16 h-16 c-1 0-3 -.4-3 -2s2 -2 3 -2h8" />
      </StyledIcon>
    </StyledBtn>
  );
};

export default BtnAnimatedBurger;
