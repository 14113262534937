export const breakpoints = {
  xs: 370,
  sm: 576,
  md: 768,
  lg: 968,
  xl: 1024,
  xxl: 1200,
} as const

export const mediaQueries = {
  xs: ``,
  sm: `@media screen and (min-width: ${breakpoints.sm}px)`,
  md: `@media screen and (min-width: ${breakpoints.md}px)`,
  lg: `@media screen and (min-width: ${breakpoints.lg}px)`,
  xl: `@media screen and (min-width: ${breakpoints.xl}px)`,
  xxl: `@media screen and (min-width: ${breakpoints.xxl}px)`,

  // custom queries
  // Breakpoint only
  boMd: `@media (min-width: ${breakpoints.md}px) and (max-width: ${breakpoints.lg - 0.02}px)`,
  boLg: `@media (min-width: ${breakpoints.lg}px) and (max-width: ${breakpoints.xxl - 0.02}px)`,

  // Breakpoint down
  bdSm: `@media (max-width: ${breakpoints.sm - 0.02}px)`,
  bdMd: `@media (max-width: ${breakpoints.md - 0.02}px)`,
  bdLg: `@media (max-width: ${breakpoints.lg - 0.02}px)`,
  bdXl: `@media (max-width: ${breakpoints.xl - 0.02}px)`,
  bdXxl: `@media (max-width: ${breakpoints.xxl - 0.02}px)`,
}

export type Breakpoint = keyof typeof breakpoints

export const breakpointNames = Object.keys(breakpoints) as Breakpoint[]
