import React, { ReactElement, useMemo } from 'react'
import styled from 'styled-components'
import { CheckmarkIcon, CloseIcon, Flex, ScanLink, Text, OpenNewIcon, BscScanIcon } from '@pancakeswap/uikit'
import { ChainId } from '@pancakeswap/sdk'
import { chains } from 'utils/wagmi'
import { getBlockExploreLink } from 'utils'
import { TransactionDetails } from 'state/transactions/reducer'
import CircleLoader from '../../Loader/CircleLoader'

const TransactionState = styled.li<{ pending: boolean; success?: boolean }>`
  display: grid;
  text-decoration: none !important;
  border-radius: 8px;
  padding: 8px 16px;
  background-color: ${({ theme }) => theme.colors.gray800};
`

const StyledTransactionInner = styled(Flex)`
  column-gap: 8px;
`

const IconWrapper = styled.div<{ pending: boolean; success?: boolean }>`
  margin-left: 4px;
  color: ${({ pending, success, theme }) =>
    pending ? theme.colors.warning : success ? theme.colors.success : theme.colors.failure};
  height: 16px;
`

const icons: { [key in ChainId]?: ReactElement } = {
  [ChainId.BSC]: <BscScanIcon />,
  [ChainId.BSC_TESTNET]: <BscScanIcon />,
}

export default function Transaction({ tx, chainId }: { tx: TransactionDetails; chainId: number }) {
  const summary = tx?.summary
  const pending = !tx?.receipt
  const success = !pending && tx && (tx.receipt?.status === 1 || typeof tx.receipt?.status === 'undefined')

  const color = pending ? 'warning' : success ? 'success' : 'failure'

  const iconToShow = useMemo(() => {
    if (chainId && icons[chainId]) return icons[chainId]
    return <OpenNewIcon />
  }, [chainId])

  const iconElement = useMemo(() => {
    return React.isValidElement(iconToShow)
      ? React.cloneElement(iconToShow, {
          // @ts-ignore
          color,
          width: '16px',
          height: '16px',
        })
      : null
  }, [color, iconToShow])

  if (!chainId) return null

  return (
    <TransactionState pending={pending} success={success}>
      <Text fontWeight="500" color="white500" mb="2px">
        {chains.find((c) => c.id === chainId)?.name ?? 'Unknown network'}
      </Text>
      <StyledTransactionInner justifyContent="space-between" alignItems="center">
        <ScanLink
          fontWeight="500"
          href={getBlockExploreLink(tx.hash, 'transaction', chainId)}
          isWithoutIcon
          color={color}
        >
          {summary ?? tx.hash}
        </ScanLink>
        <Flex>
          {iconElement}

          <IconWrapper pending={pending} success={success}>
            {pending ? (
              <CircleLoader size="16px" stroke="#FFA337" />
            ) : success ? (
              <CheckmarkIcon color="success" width="16px" height="16px" />
            ) : (
              <CloseIcon color="failure" width="16px" height="16px" />
            )}
          </IconWrapper>
        </Flex>
      </StyledTransactionInner>
    </TransactionState>
  )
}
