import React from "react";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <svg
      fill="none"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      viewBox="0 0 28 28"
      {...props}
    >
      <path d="M14 3.6c5.4 5.8 5.4 15 0 20.8m0 0c-5.4-5.8-5.4-15 0-20.8" />
      <path d="M14 3.5a10.5 10.5 0 1 1 0 21m0 0a10.5 10.5 0 1 1 0-21m-10 7h20m-20 7h20" />
    </svg>
  );
};

export default Icon;
