import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path d="M2.9 6.4a1 1 0 0 0-.4-.8L.3 2.9v-.4h7l5.4 11.8 4.7-11.8H24v.4l-2 1.8a.6.6 0 0 0-.1.6v13.5a.6.6 0 0 0 .2.5L24 21v.4h-9.5V21l2-1.9c.2-.1.2-.2.2-.5v-11l-5.4 13.8h-.7L4.3 7.8V17a1.3 1.3 0 0 0 .3 1l2.6 3v.5H0V21l2.5-3a1.2 1.2 0 0 0 .4-1V6.4Z" />
    </Svg>
  );
};

export default Icon;
