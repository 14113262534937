import { SpaceProps, TypographyProps } from 'styled-system'

export const variants = {
  FAILURE: 'failure',
  WARNING: 'warning',
} as const

export type Variant = (typeof variants)[keyof typeof variants]

export interface MessageProps extends SpaceProps, TypographyProps {
  variant?: Variant
}
