import { ChainId, WBNB } from '@pancakeswap/sdk'

const PRICE_API = 'https://api.coinlore.net/api/ticker';
const XCAD_CURRENCY_API = 'https://api.xcadnetwork.com/currency/api/v2/pub/currency/address';
const CONGECKO_CURRENCY_API = `/api/price`;
// const CONGECKO_CURRENCY_API = 'https://dxcadswap.com/api/price';
// const PRICE_API = 'https://alpha.wallet-api.pancakeswap.com/v1/prices/list/'

const zeroAddress = '0x0000000000000000000000000000000000000000' as const

// duck typing for native currency, token, token info
export type CurrencyParams =
  | {
      chainId: ChainId
      address: `0x${string}`
      isNative?: false
    }
  | {
      chainId: ChainId
      isNative: true
    }


export const COIN_LORE_TOKEN_ID = {
  [`${ChainId.OPBNB}:0xaf9fe3b5ccdae78188b1f8b9a49da7ae9510f151`]: '51005',
  [`${ChainId.OPBNB}:${WBNB[ChainId.OPBNB].address}`]: '2710',
  [`${ChainId.BSC}:0x55d398326f99059ff775485246999027b3197955`]: '518',
};
export const CURRENCY_API_ADDRESS = [
  '0xd069599e718f963bd84502b49ba8f8657faf5b3a',
  '0xa026ad2ceda16ca5fc28fd3c72f99e2c332c8a26',
];
export const CURRENCY_CACHE_API = [
  '0x93ea2a6508d410490f2094fc68625522ddc5cd9f',
];

export type CurrencyKey = `${number}:${string}`

export type CurrencyUsdResult = Record<CurrencyKey, number>

export function getCurrencyKey(currencyParams?: CurrencyParams): CurrencyKey | undefined {
  if (!currencyParams) {
    return undefined
  }

  if ('isNative' in currencyParams && currencyParams.isNative === true) {
    return `${currencyParams.chainId}:${zeroAddress}`
  }
  const { chainId, address } = currencyParams
  return `${chainId}:${address.toLowerCase()}`
}

export function getCurrencyListKey(currencyListParams?: CurrencyParams[]): string | undefined {
  if (!currencyListParams) {
    return undefined
  }

  const currencyKeys = currencyListParams.map(getCurrencyKey).filter((key): key is CurrencyKey => !!key)

  const uniqueKeys = [...new Set(currencyKeys)]

  return uniqueKeys.join(',')
}

function getRequestUrl(params?: CurrencyParams): string | undefined {
  if (!params) {
    return undefined
  }
  // const infoList = Array.isArray(params) ? params : [params]
  // const key = getCurrencyListKey(infoList)
  // if (!key) {
  //   return undefined
  // }
  // const encodedKey = encodeURIComponent(key)
  // return `${PRICE_API}${encodedKey}`
  const key = getCurrencyKey(params);
  if (!key) {
    return undefined;
  }
  
  if (!params.isNative) {
    // @ts-ignore
    if (CURRENCY_API_ADDRESS.includes(params.address.toLowerCase())) {
      // @ts-ignore
      return `${XCAD_CURRENCY_API}/${params.address.toLowerCase()}`;
    }
    // @ts-ignore
    if (CURRENCY_CACHE_API.includes(params.address.toLowerCase())) {
      // @ts-ignore
      return `${CONGECKO_CURRENCY_API}/${params.address.toLowerCase()}`;
    }
  }
  return `${PRICE_API}/?id=${COIN_LORE_TOKEN_ID[key]}`;
}

export async function getCurrencyUsdPrice(currencyParams?: CurrencyParams) {
  // const prices = await getCurrencyListUsdPrice(currencyParams)
  // const key = getCurrencyKey(currencyParams)
  // return (key && prices[key]) ?? 0
  return 0;
}

export async function getCurrencyListUsdPrice(currencyListParams?: CurrencyParams[]): Promise<CurrencyUsdResult> {  
  if (!currencyListParams) {
    throw new Error(`Invalid request for currency prices.`)
  }

  // @ts-ignore
  const listOfPrices: CurrencyUsdResult = {};
  await Promise.all(
    currencyListParams.map(async currencyParam => {
      const requestUrl = getRequestUrl(currencyParam);
      if (!requestUrl) {
        return;
      }
      
      const res = await fetch(requestUrl, {
        method: 'GET'
      });
      const result: any = await res.json();
      const key = getCurrencyKey(currencyParam);
      
      if (!key) {
        return;
      }

      if (requestUrl.includes(XCAD_CURRENCY_API)) {
        listOfPrices[key] = Number(result.result.price) || 0;
      } else if (requestUrl.includes(CONGECKO_CURRENCY_API)) {
        listOfPrices[key] = Number(result.price) || 0;
      } else {
        listOfPrices[key] = result[0].price_usd;
      }
      
      // eslint-disable-next-line consistent-return
      return result;
    })
  );

  return listOfPrices as CurrencyUsdResult;
}
