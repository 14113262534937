import { useTranslation } from '@pancakeswap/localization'
import { ChainId } from '@pancakeswap/sdk'
import {
  Flex,
  InjectedModalProps,
  Modal,
  ExpertModal,
  QuestionHelper,
  Text,
  Toggle,
  PreTitle,
} from '@pancakeswap/uikit'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { useCallback, useState } from 'react'
import { useSwapActionHandlers } from 'state/swap/useSwapActionHandlers'
import { useExpertMode, useUserSingleHopOnly, useUserExpertModeAcknowledgement } from '@pancakeswap/utils/user'
import { useSubgraphHealthIndicatorManager } from 'state/user/hooks'
import { useUserTokenRisk } from 'state/user/hooks/useUserTokenRisk'
import { TOKEN_RISK } from 'components/AccessRisk'
import AccessRiskTooltips from 'components/AccessRisk/AccessRiskTooltips'
import GasSettings from './GasSettings'
import TransactionSettings from './TransactionSettings'
import { SettingsMode } from './types'
import { ScrollableContainer, StyledLabel } from './styled'

export const withCustomOnDismiss =
  (Component) =>
  ({
    onDismiss,
    customOnDismiss,
    mode,
    ...props
  }: {
    onDismiss?: () => void
    customOnDismiss: () => void
    mode: SettingsMode
  }) => {
    const handleDismiss = useCallback(() => {
      onDismiss?.()
      if (customOnDismiss) {
        customOnDismiss()
      }
    }, [customOnDismiss, onDismiss])

    return <Component {...props} mode={mode} onDismiss={handleDismiss} />
  }

const SettingsModal: React.FC<React.PropsWithChildren<InjectedModalProps>> = ({ onDismiss, mode }) => {
  const [showConfirmExpertModal, setShowConfirmExpertModal] = useState(false)
  const [showExpertModeAcknowledgement, setShowExpertModeAcknowledgement] = useUserExpertModeAcknowledgement()
  const [expertMode, setExpertMode] = useExpertMode()
  const [singleHopOnly, setSingleHopOnly] = useUserSingleHopOnly()
  const [subgraphHealth, setSubgraphHealth] = useSubgraphHealthIndicatorManager()
  const { onChangeRecipient } = useSwapActionHandlers()
  const { chainId } = useActiveChainId()
  const [tokenRisk, setTokenRisk] = useUserTokenRisk()

  const { t } = useTranslation()

  if (showConfirmExpertModal) {
    return (
      <ExpertModal
        setShowConfirmExpertModal={setShowConfirmExpertModal}
        onDismiss={onDismiss}
        toggleExpertMode={() => setExpertMode((s) => !s)}
        setShowExpertModeAcknowledgement={setShowExpertModeAcknowledgement}
      />
    )
  }

  const handleExpertModeToggle = () => {
    if (expertMode || !showExpertModeAcknowledgement) {
      onChangeRecipient(null)
      setExpertMode((s) => !s)
    } else {
      setShowConfirmExpertModal(true)
    }
  }

  return (
    <Modal title={t('Settings')} minWidth={mode === SettingsMode.GLOBAL ? '400px' : '500px'} onDismiss={onDismiss}>
      <ScrollableContainer>
        {mode === SettingsMode.GLOBAL && (
          <>
            <Flex flexDirection="column">
              <PreTitle mb="16px">{t('Global')}</PreTitle>
              <Flex justifyContent="space-between" alignItems="center" mb="16px">
                <Flex alignItems="center">
                  <Text color="gray100">{t('Subgraph Health Indicator')}</Text>
                  <QuestionHelper
                    text={t(
                      'Turn on subgraph health indicator all the time. Default is to show the indicator only when the network is delayed',
                    )}
                    ml="4px"
                  />
                </Flex>
                <Toggle
                  id="toggle-subgraph-health-button"
                  checked={subgraphHealth}
                  scale="md"
                  onChange={() => {
                    setSubgraphHealth(!subgraphHealth)
                  }}
                />
              </Flex>

              {chainId === ChainId.BSC && (
                <>
                  <Flex justifyContent="space-between" alignItems="center" mb="24px">
                    <Flex alignItems="center">
                      <Text color="gray100">{t('Token Risk Scanning')}</Text>
                      <QuestionHelper
                        text={
                          <AccessRiskTooltips
                            hasResult
                            riskLevel={TOKEN_RISK.SOME_RISK}
                            riskLevelDescription={t(
                              'Automatic risk scanning for the selected token. This scanning result is for reference only, and should NOT be taken as investment advice.',
                            )}
                          />
                        }
                        ml="4px"
                      />
                    </Flex>
                    <Toggle
                      id="toggle-username-visibility"
                      checked={tokenRisk}
                      scale="md"
                      onChange={() => {
                        setTokenRisk(!tokenRisk)
                      }}
                    />
                  </Flex>
                  <GasSettings />
                </>
              )}
            </Flex>
          </>
        )}
        {mode === SettingsMode.SWAP_LIQUIDITY && (
          <>
            <Flex pt="3px" flexDirection="column">
              <PreTitle mb="16px">{t('Swaps & Liquidity')}</PreTitle>
              <Flex justifyContent="space-between" alignItems="center" mb="24px">
                {chainId === ChainId.BSC && <GasSettings />}
              </Flex>
              <TransactionSettings />
            </Flex>
            <Flex mb="8px">
              <StyledLabel htmlFor="toggle-expert-mode-button">
                <Flex alignItems="center">
                  <Text color="gray100" letterSpacing="0.6px" lineHeight={1.75}>
                    {t('Expert Mode')}
                  </Text>
                  <QuestionHelper
                    text={t('Bypasses confirmation modals and allows high slippage trades. Use at your own risk.')}
                    ml="4px"
                  />
                </Flex>
                <Toggle
                  id="toggle-expert-mode-button"
                  scale="md"
                  checked={expertMode}
                  onChange={handleExpertModeToggle}
                />
              </StyledLabel>
            </Flex>
            <Flex mb="8px">
              <StyledLabel htmlFor="toggle-disable-multihop-button">
                <Flex alignItems="center">
                  <Text color="gray100" letterSpacing="0.6px" lineHeight={1.75}>
                    Disabled Multihops
                  </Text>
                  <QuestionHelper text="Restrict swaps to direct pairs only." ml="4px" />
                </Flex>
                <Toggle
                  id="toggle-disable-multihop-button"
                  checked={singleHopOnly}
                  scale="md"
                  onChange={() => {
                    setSingleHopOnly((s) => !s)
                  }}
                />
              </StyledLabel>
            </Flex>
            <StyledLabel htmlFor="toggle-subgraph-health-button" mb="-6px">
              <Flex alignItems="center">
                <Text color="gray100" letterSpacing="0.6px" lineHeight={1.75}>
                  {t('Subgraph Health Indicator')}
                </Text>
                <QuestionHelper
                  text={t(
                    'Turn on subgraph health indicator all the time. Default is to show the indicator only when the network is delayed',
                  )}
                  ml="4px"
                />
              </Flex>
              <Toggle
                id="toggle-subgraph-health-button"
                checked={subgraphHealth}
                scale="md"
                onChange={() => {
                  setSubgraphHealth(!subgraphHealth)
                }}
              />
            </StyledLabel>
          </>
        )}
      </ScrollableContainer>
    </Modal>
  )
}

export default SettingsModal
