import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 20 20" {...props}>
      <circle cx="10" cy="10" r="9.5" fill="url(#xcad-logo-a)" fillOpacity=".8" stroke="#4A4A4A" />
      <path
        fill="url(#xcad-logo-b)"
        d="M8.5 9.8 6.8 5.1h2.4l.9 2.8L11.7 5h2.5l-3 5 1.8 5.2h-2.5l-1-3.2-1.8 3.2H5.1l3.4-5.5Z"
      />
      <defs>
        <linearGradient id="xcad-logo-a" x1="10" x2="10.2" y1="0" y2="12.2" gradientUnits="userSpaceOnUse">
          <stop stopColor="#2F2F2F" />
          <stop offset="1" stopColor="#151515" />
        </linearGradient>
        <linearGradient id="xcad-logo-b" x1="9.7" x2="9.7" y1="15.3" y2="5.1" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#FD6422" />
          <stop offset=".3" stopColor="#FC194A" />
          <stop offset=".6" stopColor="#FC125F" />
          <stop offset="1" stopColor="#9B2AA6" />
        </linearGradient>
      </defs>
    </Svg>
  );
};

export default Icon;
