import React from "react";
import Link from "./Link";
import { LinkProps } from "./types";
import { Button } from "../Button";
import { ExternalLinkIcon } from "../Svg";

const LinkExternal: React.FC<React.PropsWithChildren<LinkProps>> = ({
  children,
  showExternalIcon = true,
  externalIcon,
  variant,
  ...props
}) => {
  return (
    <Link small external variant={variant} {...props}>
      {variant === "underlined-text" ? (
        <>
          {children}
          {showExternalIcon &&
            (externalIcon || <ExternalLinkIcon color="gray200" strokeWidth={2} width="16px" height="16px" ml="4px" />)}
        </>
      ) : (
        <Button variant="primary-text" style={{ boxShadow: "none" }}>
          {children}
          {showExternalIcon && <ExternalLinkIcon width="16px" height="16px" ml="4px" />}
        </Button>
      )}
    </Link>
  );
};

export default LinkExternal;
