export function getTimestampForDay(day: number) {
  const secondsInADay = 86400 // Number of seconds in a day
  const secondsInHalfADay = 43200 // Number of seconds in half a day (12 hours)

  // Calculate the timestamp for the start of the given day
  const timestamp = day * secondsInADay - secondsInHalfADay

  return timestamp * 1000
}

export function base1BillionToBase10(value: number) {
  return Number(value / 1000000000).toString()
}

export function base10KToBase10(value: number) {
  return Number(value / 10000).toString()
}

export const getAPY = (percentage: string) => (Number(percentage) * 365 * 100).toFixed(2)
export const getPercentage = (percentage: string) => (Number(percentage) * 100).toFixed(2)
