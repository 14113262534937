export const xcadLockedPoolAbi = [
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'user', type: 'address' },
      { indexed: false, internalType: 'uint128', name: 'amount', type: 'uint128' },
      { indexed: true, internalType: 'address', name: 'token', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'poolIndex', type: 'uint256' },
    ],
    name: 'Deposit',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'user', type: 'address' },
      { indexed: false, internalType: 'address', name: 'token', type: 'address' },
      { indexed: false, internalType: 'uint128', name: 'amount', type: 'uint128' },
      { indexed: false, internalType: 'uint256', name: 'poolIndex', type: 'uint256' },
    ],
    name: 'Harvest',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, internalType: 'uint8', name: 'version', type: 'uint8' }],
    name: 'Initialized',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, internalType: 'address', name: 'account', type: 'address' }],
    name: 'Paused',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'user', type: 'address' },
      { indexed: true, internalType: 'address', name: 'token', type: 'address' },
      { indexed: false, internalType: 'uint128', name: 'accumAmount', type: 'uint128' },
    ],
    name: 'PendingWithdraw',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'poolIndex', type: 'uint256' },
      { indexed: false, internalType: 'bool', name: 'state', type: 'bool' },
    ],
    name: 'PoolChangeState',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, internalType: 'uint256', name: 'poolIndex', type: 'uint256' }],
    name: 'PoolChanged',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32' },
      { indexed: true, internalType: 'bytes32', name: 'previousAdminRole', type: 'bytes32' },
      { indexed: true, internalType: 'bytes32', name: 'newAdminRole', type: 'bytes32' },
    ],
    name: 'RoleAdminChanged',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32' },
      { indexed: true, internalType: 'address', name: 'account', type: 'address' },
      { indexed: true, internalType: 'address', name: 'sender', type: 'address' },
    ],
    name: 'RoleGranted',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32' },
      { indexed: true, internalType: 'address', name: 'account', type: 'address' },
      { indexed: true, internalType: 'address', name: 'sender', type: 'address' },
    ],
    name: 'RoleRevoked',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'token', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256' },
    ],
    name: 'TreasuryWithdraw',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, internalType: 'address', name: 'account', type: 'address' }],
    name: 'Unpaused',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'user', type: 'address' },
      { indexed: true, internalType: 'address', name: 'token', type: 'address' },
      { indexed: false, internalType: 'uint128', name: 'pendingInterest', type: 'uint128' },
      { indexed: false, internalType: 'uint128', name: 'userDeposit', type: 'uint128' },
      { indexed: false, internalType: 'uint128', name: 'fee', type: 'uint128' },
      { indexed: false, internalType: 'uint256', name: 'poolIndex', type: 'uint256' },
    ],
    name: 'Withdraw',
    type: 'event',
  },
  {
    inputs: [],
    name: 'DEFAULT_ADMIN_ROLE',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'TREASURY_ROLE',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'contract IERC20Upgradeable', name: 'token', type: 'address' },
          { internalType: 'uint32', name: 'endDay', type: 'uint32' },
          { internalType: 'uint32', name: 'dayPercent', type: 'uint32' },
          { internalType: 'uint16', name: 'lockPeriod', type: 'uint16' },
          { internalType: 'uint16', name: 'withdrawalFee', type: 'uint16' },
          { internalType: 'uint128', name: 'maxDeposit', type: 'uint128' },
          { internalType: 'uint128', name: 'minDeposit', type: 'uint128' },
          { internalType: 'uint128', name: 'totalDeposited', type: 'uint128' },
          { internalType: 'uint128', name: 'maxPoolAmount', type: 'uint128' },
          { internalType: 'bool', name: 'depositEnabled', type: 'bool' },
        ],
        internalType: 'struct FixedStaking.Pool',
        name: '_pool',
        type: 'tuple',
      },
    ],
    name: 'addPool',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '_poolIndex', type: 'uint256' },
      {
        components: [
          { internalType: 'contract IERC20Upgradeable', name: 'token', type: 'address' },
          { internalType: 'uint32', name: 'endDay', type: 'uint32' },
          { internalType: 'uint32', name: 'dayPercent', type: 'uint32' },
          { internalType: 'uint16', name: 'lockPeriod', type: 'uint16' },
          { internalType: 'uint16', name: 'withdrawalFee', type: 'uint16' },
          { internalType: 'uint128', name: 'maxDeposit', type: 'uint128' },
          { internalType: 'uint128', name: 'minDeposit', type: 'uint128' },
          { internalType: 'uint128', name: 'totalDeposited', type: 'uint128' },
          { internalType: 'uint128', name: 'maxPoolAmount', type: 'uint128' },
          { internalType: 'bool', name: 'depositEnabled', type: 'bool' },
        ],
        internalType: 'struct FixedStaking.Pool',
        name: '_pool',
        type: 'tuple',
      },
    ],
    name: 'changePool',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint32', name: '', type: 'uint32' },
      { internalType: 'uint256', name: '', type: 'uint256' },
    ],
    name: 'dailyDeposit',
    outputs: [{ internalType: 'uint128', name: '', type: 'uint128' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint32', name: '', type: 'uint32' },
      { internalType: 'uint256', name: '', type: 'uint256' },
    ],
    name: 'dailyWithdraw',
    outputs: [{ internalType: 'uint128', name: '', type: 'uint128' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '_poolIndex', type: 'uint256' },
      { internalType: 'uint128', name: '_amount', type: 'uint128' },
    ],
    name: 'deposit',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getCurrentDay',
    outputs: [{ internalType: 'uint32', name: 'currentDay', type: 'uint32' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'poolId', type: 'uint256' },
      { internalType: 'uint32', name: 'firstDay', type: 'uint32' },
      { internalType: 'uint256', name: 'count', type: 'uint256' },
    ],
    name: 'getDailyBalances',
    outputs: [
      { internalType: 'uint128[]', name: '_deposit', type: 'uint128[]' },
      { internalType: 'uint128[]', name: '_withdraw', type: 'uint128[]' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getPoolCount',
    outputs: [{ internalType: 'uint256', name: 'poolIndex', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }],
    name: 'getRoleAdmin',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '_user', type: 'address' }],
    name: 'getUserInfo',
    outputs: [
      {
        components: [
          {
            components: [
              { internalType: 'contract IERC20Upgradeable', name: 'token', type: 'address' },
              { internalType: 'uint32', name: 'endDay', type: 'uint32' },
              { internalType: 'uint32', name: 'dayPercent', type: 'uint32' },
              { internalType: 'uint16', name: 'lockPeriod', type: 'uint16' },
              { internalType: 'uint16', name: 'withdrawalFee', type: 'uint16' },
              { internalType: 'uint128', name: 'maxDeposit', type: 'uint128' },
              { internalType: 'uint128', name: 'minDeposit', type: 'uint128' },
              { internalType: 'uint128', name: 'totalDeposited', type: 'uint128' },
              { internalType: 'uint128', name: 'maxPoolAmount', type: 'uint128' },
              { internalType: 'bool', name: 'depositEnabled', type: 'bool' },
            ],
            internalType: 'struct FixedStaking.Pool',
            name: 'pool',
            type: 'tuple',
          },
          {
            components: [
              { internalType: 'uint128', name: 'userDeposit', type: 'uint128' },
              { internalType: 'uint128', name: 'accrueInterest', type: 'uint128' },
              { internalType: 'uint32', name: 'lastDayAction', type: 'uint32' },
              { internalType: 'bool', name: 'endLockTime', type: 'bool' },
            ],
            internalType: 'struct FixedStaking.UserInfo',
            name: 'userInfo',
            type: 'tuple',
          },
          { internalType: 'uint32', name: 'timestampEndLockPeriod', type: 'uint32' },
        ],
        internalType: 'struct FixedStaking.InfoFront[]',
        name: 'info',
        type: 'tuple[]',
      },
      { internalType: 'uint32', name: 'currentDay', type: 'uint32' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'bytes32', name: 'role', type: 'bytes32' },
      { internalType: 'address', name: 'account', type: 'address' },
    ],
    name: 'grantRole',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '_poolIndex', type: 'uint256' }],
    name: 'harvest',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'bytes32', name: 'role', type: 'bytes32' },
      { internalType: 'address', name: 'account', type: 'address' },
    ],
    name: 'hasRole',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_treasury', type: 'address' },
      { internalType: 'address', name: '_treasuryAdmin', type: 'address' },
    ],
    name: 'initialize',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  { inputs: [], name: 'pause', outputs: [], stateMutability: 'nonpayable', type: 'function' },
  {
    inputs: [],
    name: 'paused',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint32', name: '', type: 'uint32' },
      { internalType: 'address', name: '', type: 'address' },
    ],
    name: 'pendingWithdraw',
    outputs: [{ internalType: 'uint128', name: '', type: 'uint128' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    name: 'pools',
    outputs: [
      { internalType: 'contract IERC20Upgradeable', name: 'token', type: 'address' },
      { internalType: 'uint32', name: 'endDay', type: 'uint32' },
      { internalType: 'uint32', name: 'dayPercent', type: 'uint32' },
      { internalType: 'uint16', name: 'lockPeriod', type: 'uint16' },
      { internalType: 'uint16', name: 'withdrawalFee', type: 'uint16' },
      { internalType: 'uint128', name: 'maxDeposit', type: 'uint128' },
      { internalType: 'uint128', name: 'minDeposit', type: 'uint128' },
      { internalType: 'uint128', name: 'totalDeposited', type: 'uint128' },
      { internalType: 'uint128', name: 'maxPoolAmount', type: 'uint128' },
      { internalType: 'bool', name: 'depositEnabled', type: 'bool' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'bytes32', name: 'role', type: 'bytes32' },
      { internalType: 'address', name: 'account', type: 'address' },
    ],
    name: 'renounceRole',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'bytes32', name: 'role', type: 'bytes32' },
      { internalType: 'address', name: 'account', type: 'address' },
    ],
    name: 'revokeRole',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '_poolIndex', type: 'uint256' },
      { internalType: 'uint32', name: '_endDay', type: 'uint32' },
    ],
    name: 'setPoolEndDay',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '_poolIndex', type: 'uint256' },
      { internalType: 'bool', name: '_state', type: 'bool' },
    ],
    name: 'setPoolState',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_treasury', type: 'address' },
      { internalType: 'address', name: '_treasuryAdmin', type: 'address' },
    ],
    name: 'setTreasury',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'treasury',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'treasuryAdmin',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  { inputs: [], name: 'unpause', outputs: [], stateMutability: 'nonpayable', type: 'function' },
  {
    inputs: [
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'uint256', name: '', type: 'uint256' },
    ],
    name: 'userInfo',
    outputs: [
      { internalType: 'uint128', name: 'userDeposit', type: 'uint128' },
      { internalType: 'uint128', name: 'accrueInterest', type: 'uint128' },
      { internalType: 'uint32', name: 'lastDayAction', type: 'uint32' },
      { internalType: 'bool', name: 'endLockTime', type: 'bool' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'address', name: '', type: 'address' },
    ],
    name: 'userPendingWithdraw',
    outputs: [{ internalType: 'uint32', name: '', type: 'uint32' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '_poolIndex', type: 'uint256' }],
    name: 'withdraw',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract IERC20Upgradeable', name: '_token', type: 'address' },
      { internalType: 'uint256', name: '_amount', type: 'uint256' },
    ],
    name: 'withdrawToken',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
] as const
