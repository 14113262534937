import { useCallback } from 'react'
import { Modal, ModalBody, Text, Button, InjectedModalProps } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import isEmpty from 'lodash/isEmpty'
import groupBy from 'lodash/groupBy'
import { useAllSortedRecentTransactions } from 'state/transactions/hooks'
import { TransactionDetails } from 'state/transactions/reducer'
import { useAppDispatch } from 'state'
import { clearAllTransactions } from 'state/transactions/actions'
import { useAccount } from 'wagmi'
import styled from 'styled-components'
import { AutoRow } from '../../Layout/Row'
import Transaction from './Transaction'
import ConnectWalletButton from '../../ConnectWalletButton'

export const StyledNoRecentText = styled(Text)`
  height: 200px;
  display: grid;
  place-items: center;
`

export const StyledList = styled.ul`
  list-style: none;
  display: grid;
  row-gap: 8px;
  margin-right: -16px;
  padding-right: 16px;
  overflow: auto;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-top: -12px;
  margin-bottom: -12px;
  mask: linear-gradient(transparent 0, #fff 12px, #fff calc(100% - 12px), transparent 100%);
`

function renderTransactions(transactions: TransactionDetails[], chainId: number) {
  return transactions.map((tx) => {
    return <Transaction key={tx.hash + tx.addedTime} tx={tx} chainId={chainId} />
  })
}

const TransactionsModal: React.FC<React.PropsWithChildren<InjectedModalProps>> = ({ onDismiss }) => {
  const { address: account } = useAccount()
  const dispatch = useAppDispatch()
  const sortedRecentTransactions = useAllSortedRecentTransactions()

  const { t } = useTranslation()

  const hasTransactions = !isEmpty(sortedRecentTransactions)

  const clearAllTransactionsCallback = useCallback(() => {
    dispatch(clearAllTransactions())
  }, [dispatch])

  return (
    <Modal minWidth="500px" title={t('Transactions')} onDismiss={onDismiss}>
      {account ? (
        <ModalBody overflow="hidden" pr="16px" mr="-16px" mb="-12px" pb="12px">
          <AutoRow mb="12px" style={{ justifyContent: 'space-between' }}>
            <Text color="gray300" fontWeight="500">
              {t('Recent Transactions')}
            </Text>
            {hasTransactions && (
              <Button variant="primary-text" onClick={clearAllTransactionsCallback}>
                {t('Clear all')}
              </Button>
            )}
          </AutoRow>
          {hasTransactions ? (
            <StyledList>
              {Object.entries(sortedRecentTransactions).map(([chainId, transactions]) => {
                const chainIdNumber = Number(chainId)
                const groupedTransactions = groupBy(Object.values(transactions), (trxDetails) =>
                  Boolean(trxDetails.receipt),
                )

                const confirmed = groupedTransactions.true ?? []
                const pending = groupedTransactions.false ?? []

                return (
                  <>
                    {renderTransactions(pending, chainIdNumber)}
                    {renderTransactions(confirmed, chainIdNumber)}
                  </>
                )
              })}
            </StyledList>
          ) : (
            <StyledNoRecentText color="gray400" lineHeight="1.28" small>
              {t('No recent transactions')}
            </StyledNoRecentText>
          )}
        </ModalBody>
      ) : (
        <ConnectWalletButton />
      )}
    </Modal>
  )
}

export default TransactionsModal
