import styled from "styled-components";
import { darkColors } from "../../theme/colors";
import { Box, Flex, Grid } from "../Box";

import { Text } from "../Text";

export const StyledFooter = styled(Grid)`
  ${({ theme }) => theme.mediaQueries.lg} {
    padding-top: 96px;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    row-gap: 72px;
    padding-bottom: 96px;
    justify-content: space-between;
  }

  ${({ theme }) => theme.mediaQueries.boMd} {
    padding-top: 36px;
  }

  ${({ theme }) => theme.mediaQueries.bdMd} {
    justify-items: center;
    row-gap: 24px;
    padding-bottom: 24px;
    padding-top: 24px;
  }
`;

export const StyledMenu = styled(Flex)`
  grid-area: menu;
  column-gap: 32px;

  ${({ theme }) => theme.mediaQueries.bdMd} {
    flex-direction: column;
    row-gap: 48px;
    justify-self: start;
    margin-bottom: 24px;
  }
`;

export const StyledCopy = styled(Grid)`
  width: 100%;
  grid-gap: 24px;

  ${({ theme }) => theme.mediaQueries.md} {
    align-items: center;
    grid-template-columns: 1fr auto 1fr;
  }

  ${({ theme }) => theme.mediaQueries.bdMd} {
    justify-items: center;
  }
`;

export const StyledMinimalCopy = styled(Text)`
  ${({ theme }) => theme.mediaQueries.md} {
    justify-self: end;
  }
`;

export const StyledLogo = styled.div`
  ${({ theme }) => theme.mediaQueries.md} {
    justify-self: center;
  }
`;

export const StyledLink = styled.a`
  text-decoration: underline;
  transition: color 150ms ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.colors.white800};
  }
`;

export const StyledList = styled.ul`
  list-style: none;
  align-content: start;
  display: grid;
  grid-gap: 16px 48px;

  ${({ theme }) => theme.mediaQueries.xxl} {
    grid-template-rows: repeat(3, 1fr);
    grid-auto-flow: column;
  }
`;

export const StyledListItem = styled.li`
  font-size: 18px;
  font-weight: 500;
  line-height: 1.33;
`;

export const StyledIconMobileContainer = styled(Box)`
  margin-bottom: 24px;
`;

export const StyledToolsContainer = styled(Flex)`
  border-color: ${darkColors.cardBorder};
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-style: solid;
  padding: 24px 0;
  margin-bottom: 24px;

  ${({ theme }) => theme.mediaQueries.sm} {
    border-top-width: 0;
    border-bottom-width: 0;
    padding: 0 0;
    margin-bottom: 0;
  }
`;

export const StyledText = styled.span`
  color: ${darkColors.text};
`;
