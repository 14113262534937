import styled from 'styled-components'
import { variant } from 'styled-system'
import { Text } from '@pancakeswap/uikit'

import { styleVariants } from './theme'

export const StyledMessage = styled(Text)`
  padding: 4px 12px;
  line-height: 1.75;
  letter-spacing: 0.6px;
  border-radius: 8px;

  ${variant({
    variants: styleVariants,
  })}
`

export default null
