import React from "react";
import { Box, Grid } from "../Box";
import { Link } from "../Link";
import {
  StyledFooter,
  StyledMenu,
  StyledList,
  StyledListItem,
  StyledCopy,
  StyledMinimalCopy,
  StyledLogo,
  StyledLink,
} from "./styles";

import XcadPrice from "../XcadPrice/XcadPrice";
import { LogoWithTextIcon } from "../Svg";
import { FooterProps } from "./types";
import { Container } from "../Layouts";
import { Text } from "../Text";
import SocialLinks from "./Components/SocialLinks";

const MenuItem: React.FC<React.PropsWithChildren<FooterProps>> = ({ items, xcadPriceUsd, ...props }) => {
  return (
    <Container position="relative">
      <StyledFooter
        data-theme="dark"
        gridTemplateAreas={["'menu''socials''copy'", null, null, "'menu socials''copy copy'"]}
        {...props}
      >
        <StyledMenu justifyContent="space-between" alignItems="flex-start">
          {items?.map((item) => (
            <Box>
              <Text
                color="white500"
                fontSize="12px"
                fontWeight="500"
                textTransform="uppercase"
                letterSpacing="4.3px"
                lineHeight="1.3"
                mb="24px"
              >
                {item.label}
              </Text>
              <StyledList key={item.label}>
                {item.items?.map(({ label, href }) => (
                  <StyledListItem key={label}>
                    <Link
                      data-theme="dark"
                      href={href}
                      target="_blank"
                      rel="noreferrer noopener"
                      color="white"
                      fontSize="18px"
                      lineHeight="1.33"
                      fontWeight="500"
                    >
                      {label}
                    </Link>
                  </StyledListItem>
                ))}
              </StyledList>
            </Box>
          ))}
        </StyledMenu>
        <Grid gridArea="socials" gridRowGap="16px" alignItems="center" justifyItems={["center", null, null, "end"]}>
          <Text
            color="white500"
            fontSize="12px"
            fontWeight="500"
            textTransform="uppercase"
            letterSpacing="4.3px"
            lineHeight="1.3"
          >
            Join our community
          </Text>
          <SocialLinks />
          <XcadPrice xcadPriceUsd={xcadPriceUsd} />
        </Grid>

        <StyledCopy gridArea="copy">
          <Text color="white500" fontSize="13px" lineHeight="1.23">
            All Rights Reserved © dXCAD DEX {new Date().getFullYear()}
          </Text>
          <StyledLogo>
            <LogoWithTextIcon width="100px" />
          </StyledLogo>
          <StyledMinimalCopy color="white500" fontSize="13px" lineHeight="1.23">
            by{" "}
            <StyledLink href="https://min.studio/" target="_blank" rel="noreferrer">
              Minimal Studio
            </StyledLink>
          </StyledMinimalCopy>
        </StyledCopy>
      </StyledFooter>
    </Container>
  );
};

export default MenuItem;
