import BigNumber from 'bignumber.js'
import { BIG_ZERO } from '@pancakeswap/utils/bigNumber'
import { ChainId } from '@pancakeswap/sdk'
import { XCAD } from '@pancakeswap/tokens'
import { Address } from 'viem'

import fromPairs from 'lodash/fromPairs'
import { OnChainProvider, SerializedLockedVault, SerializedPool, VaultKey } from '../types'
import { getXcadFlexibleSideVaultAddress } from './getAddresses'
import { xcadPoolAbi } from '../abis/XcadPoolAbi'
import { xcadLockedPoolAbi } from '../abis/XcadLockedPoolAbi'
import { base1BillionToBase10, getAPY, getTimestampForDay } from '../utils/lockedPoolUtils'
import { SECONDS_IN_YEAR, getPoolsConfig } from '../constants'

interface Params {
  xcadVaultAddress?: Address
  lockedPools?: Array<SerializedPool>
  chainId: ChainId
  provider: OnChainProvider
}

const balanceOfAbi = [
  {
    inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
] as const

export const fetchVaultPoolsData = async ({ chainId, provider, lockedPools }: Params) => {
  const client = provider({ chainId })

  const poolsData = await client.multicall({
    contracts: lockedPools?.map(({ lockedAddress, lockedPoolId }) => ({
      address: lockedAddress,
      abi: xcadLockedPoolAbi,
      functionName: 'pools',
      args: [lockedPoolId],
    })) as any[],
    allowFailure: true,
  })

  return fromPairs(
    lockedPools?.map((pool, index) => {
      const res: any = poolsData[index].result
      return [
        pool.sousId,
        poolsData[index].status === 'success' && res
          ? {
              token: res[0],
              endDay: res[1],
              dayPercent: base1BillionToBase10(res[2]),
              lockPeriod: res[3],
              withdrawalFee: res[4],
              maxDeposit: new BigNumber(res[5].toString()).toJSON(),
              minDeposit: new BigNumber(res[6].toString()).toJSON(),
              totalDeposited: new BigNumber(res[7].toString()).toJSON(),
              maxPoolAmount: new BigNumber(res[8].toString()).toJSON(),
              depositEnabled: res[9],
            }
          : {
              token: null,
              endDay: null,
              dayPercent: null,
              lockPeriod: null,
              withdrawalFee: null,
              maxDeposit: null,
              minDeposit: null,
              totalDeposited: null,
              maxPoolAmount: null,
              depositEnabled: null,
            },
      ]
    }),
  )
}

export const fetchVaultPoolsBalance = async ({ chainId, provider, lockedPools }: Params) => {
  const client = provider({ chainId })

  const balances: any = await client.multicall({
    contracts: lockedPools?.map(({ lockedAddress, stakingToken }) => ({
      abi: balanceOfAbi,
      address: stakingToken.address,
      functionName: 'balanceOf',
      args: [lockedAddress],
    })) as any[],
    allowFailure: true,
  })

  return fromPairs(
    lockedPools?.map((pool, index) => [
      pool.sousId,
      balances[index].status === 'success' && balances[index].result
        ? new BigNumber(balances[index].result.toString()).toJSON()
        : '0',
    ]),
  )
}

export const fetchVaultPoolsCurrentDay = async ({ chainId, provider, lockedPools }: Params) => {
  const client = provider({ chainId })

  const currentDays: any = await client.multicall({
    contracts: lockedPools?.map(({ lockedAddress }) => ({
      abi: xcadLockedPoolAbi,
      address: lockedAddress,
      functionName: 'getCurrentDay',
    })) as any[],
    allowFailure: true,
  })

  return fromPairs(
    lockedPools?.map((pool, index) => [
      pool.sousId,
      currentDays[index].status === 'success' && currentDays[index].result ? currentDays[index].result.toString() : '0',
    ]),
  )
}

export const fetchPublicVaultData = async ({ chainId, provider }: Params): Promise<SerializedLockedVault[]> => {
  try {
    const poolsConfig = getPoolsConfig(chainId) || []
    const lockedPools = poolsConfig.filter(({ vaultKey }) => vaultKey === VaultKey.CakeVault)
    const [poolsData, balances, currentDays, stakedTokenPrice] = await Promise.all([
      fetchVaultPoolsData({ chainId, provider, lockedPools }),
      fetchVaultPoolsBalance({ chainId, provider, lockedPools }),
      fetchVaultPoolsCurrentDay({ chainId, provider, lockedPools }),
      fetch('https://api.coinlore.net/api/ticker/?id=51005').then((res) => res.json()),
    ])

    const xcadPriceBN = new BigNumber(parseFloat(stakedTokenPrice[0].price_usd))

    return (
      lockedPools?.map((pool) => {
        const withdrawalFeePeriodInSeconds = +poolsData[pool.sousId].lockPeriod * 86400
        return {
          sousId: pool.sousId,
          totalShares: poolsData[pool.sousId].totalDeposited || '0',
          totalLockedAmount: poolsData[pool.sousId].totalDeposited || '0',
          totalXcadInVault: balances[pool.sousId],
          apy: getAPY(poolsData[pool.sousId].dayPercent || '0'),
          withdrawalFee: new BigNumber(poolsData[pool.sousId].withdrawalFee || '0').toJSON(),
          endDay: poolsData[pool.sousId].endDay.toString(),
          endDayTimestamp: getTimestampForDay(poolsData[pool.sousId].endDay).toString(),
          depositEnabled: poolsData[pool.sousId].depositEnabled,
          isFinished: currentDays[pool.sousId] > poolsData[pool.sousId].endDay,
          stakedTokenPrice: xcadPriceBN.toJSON(),
          lockPeriod: withdrawalFeePeriodInSeconds.toString(),
          currentDay: currentDays[pool.sousId].toString(),
          fees: {
            withdrawalFee: new BigNumber(poolsData[pool.sousId].withdrawalFee || '0').toJSON(),
            withdrawalFeePeriod: withdrawalFeePeriodInSeconds.toString(),
          },
        }
      }) || []
    )
  } catch (error) {
    return []
  }
}

export const fetchPublicFlexibleSideVaultData = async ({
  chainId,
  xcadVaultAddress = getXcadFlexibleSideVaultAddress(chainId),
  provider,
}: Params) => {
  try {
    const client = provider({ chainId })

    const [totalXcadInVault, totalShares, rewardRate] = await client.multicall({
      contracts: [
        {
          abi: balanceOfAbi,
          // @ts-ignore
          address: XCAD[chainId].address,
          functionName: 'balanceOf',
          args: [xcadVaultAddress],
        },
        {
          abi: xcadPoolAbi,
          address: xcadVaultAddress,
          functionName: 'totalSupply',
        },
        {
          abi: xcadPoolAbi,
          address: xcadVaultAddress,
          functionName: 'rewardRate',
        },
      ],
      allowFailure: true,
    })

    const stakedTokenPrice = await fetch('https://api.coinlore.net/api/ticker/?id=51005').then((res) => res.json())

    const xcadPriceBN = new BigNumber(parseFloat(stakedTokenPrice[0].price_usd))

    const totalSharesAsBigNumber =
      totalShares.status === 'success' ? new BigNumber(totalShares.result.toString()) : BIG_ZERO
    const apyAsBigNumber =
      rewardRate.status === 'success'
        ? new BigNumber(rewardRate.result.toString()).multipliedBy(SECONDS_IN_YEAR).shiftedBy(-18)
        : BIG_ZERO
    const totalXcadBN =
      totalXcadInVault.status === 'success' ? new BigNumber(totalXcadInVault.result.toString()) : BIG_ZERO

    const poolsConfig = getPoolsConfig(chainId) || []
    const flexiblePool = poolsConfig.find(({ vaultKey }) => vaultKey === VaultKey.CakeFlexibleSideVault)

    return {
      stakedTokenPrice: xcadPriceBN.toJSON(),
      totalShares: totalSharesAsBigNumber.toJSON(),
      flexibleApy: apyAsBigNumber.toJSON(),
      totalXcadInVault: totalXcadBN.toJSON(),
      isBurnable: flexiblePool?.isBurnable,
    }
  } catch (error) {
    return {
      stakedTokenPrice: null,
      totalShares: null,
      flexibleApy: null,
      totalXcadInVault: null,
      isBurnable: null,
    }
  }
}

export const fetchFlexibleVaultFees = async ({
  chainId,
  xcadVaultAddress = getXcadFlexibleSideVaultAddress(chainId),
  provider,
}: Params) => {
  try {
    const client = provider({ chainId })

    const [withdrawalFeePeriod] = await client.multicall({
      contracts: [
        {
          abi: xcadPoolAbi,
          address: xcadVaultAddress,
          functionName: 'lockPeriod',
        },
      ],
      allowFailure: false,
    })

    return {
      withdrawalFee: 1000,
      withdrawalFeePeriod: Number(withdrawalFeePeriod),
    }
  } catch (error) {
    return {
      withdrawalFee: null,
      withdrawalFeePeriod: null,
    }
  }
}
