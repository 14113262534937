import { ChainId } from '@pancakeswap/sdk'
import { Address } from 'viem'

import { SupportedChainId } from './supportedChains'

export type ContractAddresses<T extends ChainId = SupportedChainId> = {
  [chainId in T]: Address
}

export const XCAD_VAULT = {
  [ChainId.BSC]: '0xB4aAc2126c8Ad0Ce64E7DAA9A77862940cD1D2cC',
  [ChainId.BSC_TESTNET]: '0x83a32F43bE27721D8c80a50baaf668645d04B0f6',
} as const satisfies ContractAddresses<SupportedChainId>

export const XCAD_FLEXIBLE_SIDE_VAULT = {
  [ChainId.BSC]: '0x15dEE75Dd77Dcf86A7060ce085d84B3BEcf300E5',
  [ChainId.BSC_TESTNET]: '0x47fd6B44059cB0A5d6e196ecfB81c75C610882cD',
} as const satisfies ContractAddresses<SupportedChainId>
