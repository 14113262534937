import { ChainId } from '@pancakeswap/sdk'

import { XCAD_FLEXIBLE_SIDE_VAULT, XCAD_VAULT } from '../constants/contracts'
import { getContractAddress } from '../utils'

export function getXcadFlexibleSideVaultAddress(chainId: ChainId) {
  return getContractAddress(XCAD_FLEXIBLE_SIDE_VAULT, chainId)
}

export function getXcadVaultAddress(chainId: ChainId) {
  return getContractAddress(XCAD_VAULT, chainId)
}
