import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 140 140" {...props}>
      <rect width="140" height="140" fill="#111317" rx="70" />
      <path fill="none" stroke="url(#checkmark-fill-a)" strokeWidth="4" d="M44 71.6 60.3 88 96 52" />
      <defs>
        <linearGradient id="checkmark-fill-a" x1="44" x2="96" y1="70" y2="70" gradientUnits="userSpaceOnUse">
          <stop stopColor="#EE8100" />
          <stop offset=".5" stopColor="#EA2B57" />
          <stop offset="1" stopColor="#7322A6" />
        </linearGradient>
      </defs>
    </Svg>
  );
};

export default Icon;
