import styled from "styled-components";
import { Box, BoxProps } from "../Box";

const StyledContainer = styled(Box)<BoxProps>`
  width: 100%;
`;

const Container: React.FC<React.PropsWithChildren<BoxProps>> = ({ children, ...props }) => (
  <StyledContainer px={[24, null, 32, 48, null, 80]} mx="auto" maxWidth="1440px" {...props}>
    {children}
  </StyledContainer>
);

export default Container;
