import React from "react";
import styled from "styled-components";
import { FlexProps } from "../../Box";
import Flex from "../../Box/Flex";
import Link from "../../Link/Link";
import { socials } from "../config";

const StyledLinksWrapper = styled(Flex)`
  column-gap: 24px;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
`;

const SocialLinks: React.FC<React.PropsWithChildren<FlexProps>> = ({ ...props }) => {
  return (
    <StyledLinksWrapper {...props} data-theme="dark">
      {socials.map((social) => {
        const iconProps = {
          width: "24px",
          color: "white",
          hoverColor: "orange",
          style: { cursor: "pointer" },
        };
        const Icon = social.icon;

        return (
          <Link external key={social.label} href={social.href} aria-label={social.label}>
            <Icon {...iconProps} />
          </Link>
        );
      })}
    </StyledLinksWrapper>
  );
};

export default React.memo(SocialLinks, () => true);
