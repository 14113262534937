import {
  Box,
  Button,
  Flex,
  InjectedModalProps,
  Message,
  Skeleton,
  Text,
  CopyAddress,
  FlexGap,
  ScanLink,
  MessageText,
  Grid,
} from '@pancakeswap/uikit'
import { ChainId, WNATIVE } from '@pancakeswap/sdk'
import { XCAD_TESTNET } from '@pancakeswap/tokens'
import { FetchStatus } from 'config/constants/types'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useTranslation } from '@pancakeswap/localization'
import useAuth from 'hooks/useAuth'
import useNativeCurrency from 'hooks/useNativeCurrency'
import useTokenBalance, { useBSCCakeBalance } from 'hooks/useTokenBalance'
import { ChainLogo } from 'components/Logo/ChainLogo'

import { getBlockExploreLink, getBlockExploreName } from 'utils'
import { getFullDisplayBalance, formatBigInt } from '@pancakeswap/utils/formatBalance'
import { useBalance } from 'wagmi'
import { useDomainNameForAddress } from 'hooks/useDomain'

interface WalletInfoProps {
  hasLowNativeBalance: boolean
  switchView: (newIndex: number) => void
  onDismiss: InjectedModalProps['onDismiss']
}

const WalletInfo: React.FC<WalletInfoProps> = ({ hasLowNativeBalance, onDismiss }) => {
  const { t } = useTranslation()
  const { account, chainId, chain } = useActiveWeb3React()
  const { domainName } = useDomainNameForAddress(account)
  const bnbBalance = useBalance({ address: account, chainId: ChainId.BSC })
  const tBnbBalance = useBalance({ address: account, chainId: ChainId.BSC_TESTNET })
  const native = useNativeCurrency()
  const wBNBToken = WNATIVE[ChainId.BSC]
  const xcadTestToken = XCAD_TESTNET
  const { balance: wBNBBalance, fetchStatus: wBNBFetchStatus } = useTokenBalance(wBNBToken?.address, true)
  const { balance: cakeBalance, fetchStatus: cakeFetchStatus } = useBSCCakeBalance()
  const { balance: xcadTestBalance, fetchStatus: xcadTestFetchStatus } = useTokenBalance(xcadTestToken?.address)
  const { logout } = useAuth()

  const handleLogout = () => {
    onDismiss?.()
    logout()
  }

  return (
    <>
      <Text color="gray300" fontWeight="500" mb="8px">
        {t('Your Address')}
      </Text>
      <FlexGap flexDirection="column" mb="8px" gap="8px">
        <CopyAddress tooltipMessage={t('Copied')} account={account} />
        {domainName ? <Text color="gray100">{domainName}</Text> : null}
      </FlexGap>
      {hasLowNativeBalance && (
        <Message variant="warning" mb="24px">
          <Grid>
            <MessageText isHighlighted>
              {t('%currency% Balance Low', {
                currency: native.symbol,
              })}
            </MessageText>
            <MessageText>
              {t('You need %currency% for transaction fees.', {
                currency: native.symbol,
              })}
            </MessageText>
          </Grid>
        </Message>
      )}

      <Box mb="16px">
        <Flex justifyContent="space-between" alignItems="center" mb="8px">
          <Flex backgroundColor="gray800" borderRadius="16px" p="4px" pr="16px">
            <ChainLogo chainId={chainId} />
            <Text color="gray100" fontWeight="500" ml="4px">
              {chain?.name}
            </Text>
          </Flex>
          <ScanLink color="orange" chainId={chainId} href={getBlockExploreLink(account, 'address', chainId)}>
            {getBlockExploreName(chainId)}
          </ScanLink>
        </Flex>

        {chainId === 56 ? (
          <Flex alignItems="center" justifyContent="space-between">
            <Text color="gray200">BNB {t('Balance')}</Text>
            {!bnbBalance.isFetched ? (
              <Skeleton height="24px" width="60px" />
            ) : (
              <Flex alignItems="center" justifyContent="center">
                <Text fontWeight="500" color="gray100">
                  {formatBigInt(bnbBalance?.data?.value ?? 0n, 6)}
                </Text>
              </Flex>
            )}
          </Flex>
        ) : (
          <Flex alignItems="center" justifyContent="space-between">
            <Text color="gray200">tBNB {t('Balance')}</Text>
            {!tBnbBalance.isFetched ? (
              <Skeleton height="24px" width="60px" />
            ) : (
              <Flex alignItems="center" justifyContent="center">
                <Text fontWeight="500" color="gray100">
                  {formatBigInt(tBnbBalance?.data?.value ?? 0n, 6)}
                </Text>
              </Flex>
            )}
          </Flex>
        )}
        {wBNBBalance.gt(0) && (
          <Flex alignItems="center" justifyContent="space-between">
            <Text color="gray200">WBNB {t('Balance')}</Text>
            {wBNBFetchStatus !== FetchStatus.Fetched ? (
              <Skeleton height="24px" width="60px" />
            ) : (
              <Text fontWeight="500" color="gray100">
                {getFullDisplayBalance(wBNBBalance, wBNBToken.decimals, 6)}
              </Text>
            )}
          </Flex>
        )}
        <Flex alignItems="center" justifyContent="space-between">
          {chainId === 97 ? (
            <>
              <Text color="gray200">tXCAD {t('Balance')}</Text>
              {xcadTestFetchStatus !== FetchStatus.Fetched ? (
                <Skeleton height="24px" width="60px" />
              ) : (
                <Text fontWeight="500" color="gray100">
                  {getFullDisplayBalance(xcadTestBalance, wBNBToken.decimals, 6)}
                </Text>
              )}
            </>
          ) : (
            <>
              <Text color="gray200">{t('XCAD Balance')}</Text>
              {cakeFetchStatus !== FetchStatus.Fetched ? (
                <Skeleton height="24px" width="60px" />
              ) : (
                <Text fontWeight="500" color="gray100">
                  {formatBigInt(cakeBalance, 3)}
                </Text>
              )}
            </>
          )}
        </Flex>
      </Box>
      <Button variant="secondary" width="100%" minHeight={48} onClick={handleLogout}>
        {t('Disconnect Wallet')}
      </Button>
    </>
  )
}

export default WalletInfo
