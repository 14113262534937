import { FooterLinkType } from "../../../components/Footer/types";

export const footerLinks: () => FooterLinkType[] = () => [
  {
    label: "About dXCAD DEX",
    items: [
      {
        label: "User Guide",
        href: "https://docs.dxcadswap.com/",
      },
      {
        label: "Support",
        href: "https://t.me/dxcadofficial",
      },
    ],
  },
];
