import { vars } from "@pancakeswap/ui/css/vars.css";
import { scales, variants } from "./types";

export const scaleVariants = {
  [scales.LG]: {
    padding: "16px 32px",
  },
  [scales.MD]: {
    height: "48px",
    padding: "0 24px",
  },
  [scales.SM]: {
    height: "32px",
    padding: "0 16px",
  },
  [scales.XS]: {
    height: "20px",
    fontSize: "12px",
    padding: "0 8px",
  },
};

export const styleVariants = {
  [variants.PRIMARY]: {
    background: vars.colors.gradientPrimary,
    color: "white",
  },
  [variants.SECONDARY]: {
    backgroundColor: vars.colors.white100,
    border: `1px solid ${vars.colors.white200}`,
    boxShadow: "none",
    color: vars.colors.white,
    transition: "background-color 150ms ease-in-out",
    ":hover": {
      backgroundColor: vars.colors.white200,
    },
  },
  [variants.TAB]: {
    backgroundColor: vars.colors.gray800,
    color: vars.colors.gray200,
    transition: "background-color 150ms ease-in-out",
    ":hover": {
      backgroundColor: vars.colors.gray500,
    },
  },
  [variants.TRANSPARENT]: {
    backgroundColor: "transparent",
    color: vars.colors.gray200,
    transition: "background-color 150ms ease-in-out",
    ":hover": {
      backgroundColor: vars.colors.gray800,
    },
  },
  [variants.OUTLINE_PRIMARY]: {
    background: vars.colors.gradientPrimary20,
    color: "white",
  },
  [variants.PRIMARY_TEXT]: {
    height: "auto",
    background: vars.colors.gradientPrimary,
    backgroundClip: "text",
    padding: 0,
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    transition: "filter 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94)",
    ":hover": {
      filter: "hue-rotate(360deg)",
    },
  },
  [variants.TERTIARY]: {
    backgroundColor: "tertiary",
    boxShadow: "none",
    color: "primary",
  },
  [variants.SUBTLE]: {
    backgroundColor: "textSubtle",
    color: "backgroundAlt",
  },
  [variants.DANGER]: {
    backgroundColor: "failure",
    color: "white",
  },
  [variants.SUCCESS]: {
    backgroundColor: "success",
    color: "white",
  },
  [variants.TEXT]: {
    backgroundColor: "transparent",
    color: "gray200",
    boxShadow: "none",
  },
  [variants.LIGHT]: {
    backgroundColor: "input",
    color: "textSubtle",
    boxShadow: "none",
  },
  [variants.BUBBLEGUM]: {
    background: vars.colors.gradientBubblegum,
    color: "textSubtle",
    boxShadow: "none",
  },
  [variants.GRAY]: {
    backgroundColor: "gray800",
    transition: "opacity 150ms ease-in-out",
    ":hover": {
      opacity: 0.6,
    },
  },
  [variants.LIGHT_GRAY]: {
    backgroundColor: "gray500",
    transition: "opacity 150ms ease-in-out",
    ":hover": {
      opacity: 0.6,
    },
  },
};
