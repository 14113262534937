import { Language } from "../LangSelector/types";
import { FooterLinkType } from "./types";
import { TwitterIcon, TelegramIcon, MediumIcon } from "../Svg";

export const footerLinks: FooterLinkType[] = [
  {
    label: "About dXCAD DEX",
    items: [
      {
        label: "User Guide",
        href: "https://docs.dxcadswap.com/",
      },
      {
        label: "Support",
        href: "https://t.me/dxcadofficial",
      },
    ],
  },
];

export const socials = [
  {
    label: "Twitter",
    icon: TwitterIcon,
    href: "https://x.com/dXCADOfficial",
  },
  {
    label: "Medium",
    icon: MediumIcon,
    href: "https://medium.com/dxcad-official",
  },
  {
    label: "Telegram",
    icon: TelegramIcon,
    href: "https://t.me/dxcadofficial",
  },
];

export const langs: Language[] = [...Array(20)].map((_, i) => ({
  code: `en${i}`,
  language: `English${i}`,
  locale: `Locale${i}`,
}));
