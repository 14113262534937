import { getAddress } from 'viem'
import { bscTokens } from '@pancakeswap/tokens'
import { defineFarmV3Configs } from '../src/defineFarmV3Configs'
import { SerializedFarmConfig } from '..'

export const farmsV3 = defineFarmV3Configs([])

const farms: SerializedFarmConfig[] = [{
  pid: 1,
  lpSymbol: 'NRG-USDT',
  lpAddress: '0x68c1B3277ADda52b35DeB4789c9A039a200686cF',
  stakingRewardsAddress: '0x0B519756a6A9212445fA8c2aE0C235B00d604Cea',
  token: bscTokens.usdt,
  quoteToken: bscTokens.nrg,
}].map((p) => ({
  // @ts-ignore
  ...p,
  // @ts-ignore
  token: p.token.serialize,
  // @ts-ignore
  quoteToken: p.quoteToken.serialize,
  // @ts-ignore
  lpAddress: getAddress(p.lpAddress),
  // @ts-ignore
  stakingRewardsAddress: getAddress(p.stakingRewardsAddress),
}))

export default farms
