import { AutoRow, Box, Button, Text } from '@pancakeswap/uikit'
import { useAppDispatch } from 'state'
import { useAllSortedRecentTransactions } from 'state/transactions/hooks'
import { useTranslation } from '@pancakeswap/localization'
import { clearAllTransactions } from 'state/transactions/actions'
import { TransactionDetails } from 'state/transactions/reducer'
import isEmpty from 'lodash/isEmpty'
import groupBy from 'lodash/groupBy'
import { StyledList, StyledNoRecentText } from 'components/App/Transactions/TransactionsModal'
import Transaction from 'components/App/Transactions/Transaction'
import { useCallback } from 'react'

function renderTransactions(transactions: TransactionDetails[], chainId: number) {
  return transactions.map((tx) => {
    return <Transaction key={tx.hash + tx.addedTime} tx={tx} chainId={chainId} />
  })
}

interface WalletTransactionsProps {
  onDismiss: () => void
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const WalletTransactions: React.FC<React.PropsWithChildren<WalletTransactionsProps>> = ({ onDismiss }) => {
  const dispatch = useAppDispatch()
  const sortedRecentTransactions = useAllSortedRecentTransactions()

  const { t } = useTranslation()

  const hasTransactions = !isEmpty(sortedRecentTransactions)

  const clearAllTransactionsCallback = useCallback(() => {
    dispatch(clearAllTransactions())
  }, [dispatch])

  return (
    <Box overflow="hidden" pr="16px" mr="-16px" mb="-12px" pb="12px">
      <AutoRow mb="12px" style={{ justifyContent: 'space-between' }}>
        <Text color="gray300" fontWeight="500">
          {t('Recent Transactions')}
        </Text>
        {hasTransactions && (
          <Button variant="primary-text" onClick={clearAllTransactionsCallback}>
            {t('Clear all')}
          </Button>
        )}
      </AutoRow>
      {hasTransactions ? (
        <StyledList>
          {Object.entries(sortedRecentTransactions).map(([chainId, transactions]) => {
            const chainIdNumber = Number(chainId)
            const groupedTransactions = groupBy(Object.values(transactions), (trxDetails) =>
              Boolean(trxDetails.receipt),
            )

            const confirmed = groupedTransactions.true ?? []
            const pending = groupedTransactions.false ?? []

            return (
              <>
                {renderTransactions(pending, chainIdNumber)}
                {renderTransactions(confirmed, chainIdNumber)}
              </>
            )
          })}
        </StyledList>
      ) : (
        <StyledNoRecentText color="gray400" lineHeight="1.28" small>
          {t('No recent transactions')}
        </StyledNoRecentText>
      )}
    </Box>
  )
}

export default WalletTransactions
