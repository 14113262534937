import localFont from "next/font/local";

const generalSans = localFont({
  variable: "--bs-body-font-family",
  display: "swap",
  src: [
    {
      path: "./fonts/GeneralSans-Variable.woff2",
      weight: "400",
      style: "normal",
    },
    {
      path: "./fonts/GeneralSans-Variable.woff2",
      weight: "500",
      style: "normal",
    },
    {
      path: "./fonts/GeneralSans-Variable.woff2",
      weight: "600",
      style: "normal",
    },
  ],
});

const CustomFontComponent = () => (
  // eslint-disable-next-line react/no-unknown-property
  <style global jsx>{`
    html {
      --bs-body-font-family: ${generalSans.style.fontFamily};
      font-family: ${generalSans.style.fontFamily};
    }
    input {
      font-family: inherit;
    }
  `}</style>
);

export default CustomFontComponent;
