import React from "react";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <svg
      fill="none"
      stroke="#E6E6E6"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      viewBox="0 0 28 28"
      {...props}
    >
      <path
        d="M18.14 3.64h0a2.36 2.36 0 0 1 1.61 2.24v.06a2.36 2.36 0 0 0 2.31 2.36h.14c.87.02 1.66.52 2.06 1.3v0c.45.91.27 2-.45 2.72l-.04.05c-.9.9-.92 2.37-.04 3.3l.1.1c.6.63.8 1.54.53 2.37v0a2.36 2.36 0 0 1-2.24 1.61h-.06a2.36 2.36 0 0 0-2.36 2.31v.14a2.36 2.36 0 0 1-1.3 2.06v0c-.91.45-2 .27-2.72-.45l-.05-.04c-.9-.9-2.37-.92-3.3-.04l-.1.1c-.63.6-1.54.8-2.37.53v0a2.36 2.36 0 0 1-1.61-2.24v-.06a2.36 2.36 0 0 0-2.31-2.36H5.8a2.36 2.36 0 0 1-2.06-1.3v0c-.45-.91-.27-2 .45-2.72l.04-.05c.9-.9.92-2.37.04-3.3l-.1-.1a2.36 2.36 0 0 1-.53-2.37v0a2.36 2.36 0 0 1 2.24-1.61h.06A2.36 2.36 0 0 0 8.3 5.94V5.8c.02-.87.52-1.66 1.3-2.06v0c.91-.45 2-.27 2.72.45l.05.04c.9.9 2.37.92 3.3.04l.1-.1c.63-.6 1.54-.8 2.37-.53Z"
        clipRule="evenodd"
      />
      <circle cx="14" cy="14" r="2.92" />
    </svg>
  );
};

export default Icon;
