import { useState } from "react";
import { useTranslation } from "@pancakeswap/localization";
import styled from "styled-components";
import { Button } from "../../components/Button";
import { Text } from "../../components/Text";
import { Checkbox } from "../../components/Checkbox";
import { InjectedModalProps, Modal } from "../Modal";
import { Message } from "../../components/Message";
import { useMatchBreakpoints } from "../../contexts";

export const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 2px 6px;
  border-radius: 8px;
  margin-bottom: 24px;
  transition: background-color 250ms;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray800};
  }
`;

interface ExpertModalProps extends InjectedModalProps {
  setShowConfirmExpertModal: (show: boolean) => void;
  setShowExpertModeAcknowledgement: (show: boolean) => void;
  toggleExpertMode: () => void;
}

export const ExpertModal: React.FC<React.PropsWithChildren<ExpertModalProps>> = ({
  setShowConfirmExpertModal,
  setShowExpertModeAcknowledgement,
  toggleExpertMode,
}) => {
  const [isRememberChecked, setIsRememberChecked] = useState(false);
  const { isMobile } = useMatchBreakpoints();

  const { t } = useTranslation();

  return (
    <Modal
      title={t("Expert Mode")}
      onBack={() => setShowConfirmExpertModal(false)}
      onDismiss={() => setShowConfirmExpertModal(false)}
      style={{ width: isMobile ? "100%" : "500px" }}
    >
      <Message variant="warning" mb="24px">
        <div>
          <Text mb="2px" color="orange" lineHeight="1.75" letterSpacing="0.6px">
            {t("Only use this mode if you know what you’re doing.")}
          </Text>
          <Text color="white500" lineHeight="1.75" letterSpacing="0.6px">
            {t(
              "Expert mode turns off the 'Confirm' transaction prompt, and allows high slippage trades that often result in bad rates and lost funds."
            )}
          </Text>
        </div>
      </Message>

      <StyledLabel htmlFor="toggle-trust-checkbox">
        <Checkbox
          id="toggle-trust-checkbox"
          name="confirmed"
          type="checkbox"
          checked={isRememberChecked}
          onChange={() => setIsRememberChecked(!isRememberChecked)}
          scale="sm"
        />
        <Text ml="8px" color="gray200" style={{ userSelect: "none" }} letterSpacing="0.6px" lineHeight={1.75}>
          {t("Don’t show this again")}
        </Text>
      </StyledLabel>

      <Button
        variant="outline-primary"
        mb="8px"
        id="confirm-expert-mode"
        onClick={() => {
          // eslint-disable-next-line no-alert
          if (window.prompt(`Please type the word "confirm" to enable expert mode.`) === "confirm") {
            toggleExpertMode();
            setShowConfirmExpertModal(false);
            if (isRememberChecked) {
              setShowExpertModeAcknowledgement(false);
            }
          }
        }}
      >
        {t("Turn On Expert Mode")}
      </Button>
      <Button
        variant="secondary"
        onClick={() => {
          setShowConfirmExpertModal(false);
        }}
      >
        {t("Cancel")}
      </Button>
    </Modal>
  );
};
