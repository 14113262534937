import React, { cloneElement, Children, ReactElement } from "react";
import styled, { DefaultTheme, css } from "styled-components";
import { space } from "styled-system";
import { scales, variants } from "../Button/types";
import { ButtonMenuProps } from "./types";

interface StyledButtonMenuProps extends ButtonMenuProps {
  theme: DefaultTheme;
  withBorder?: boolean;
}

const StyledButtonMenu = styled.div<StyledButtonMenuProps>`
  border-radius: 16px;
  display: ${({ fullWidth }) => (fullWidth ? "flex" : "inline-flex")};
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
  column-gap: 8px;

  & > button,
  & > a {
    flex: ${({ fullWidth }) => (fullWidth ? 1 : "auto")};
  }

  & > button + button,
  & > a + a {
    margin-left: 2px; // To avoid focus shadow overlap
  }

  & > button,
  & a {
    box-shadow: none;
  }

  ${({ theme, withBorder }) => {
    if (withBorder)
      return css`
        padding: 4px;
        border: 1px solid ${theme.colors.gray800};
        border-radius: 12px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        button,
        a {
          border-radius: 8px;
          font-weight: 500;
        }
      `;

    return ``;
  }}

  ${({ disabled }) => {
    if (disabled) {
      return `
        pointer-events: none;
    `;
    }
    return "";
  }}
  ${space}
`;

const ButtonMenu: React.FC<React.PropsWithChildren<ButtonMenuProps>> = ({
  activeIndex = 0,
  scale = scales.MD,
  variant = variants.PRIMARY,
  variantDefault = variants.SECONDARY,
  onItemClick,
  disabled,
  children,
  fullWidth = false,
  ...props
}) => {
  return (
    <StyledButtonMenu disabled={disabled} fullWidth={fullWidth} {...props}>
      {Children.map(children, (child: ReactElement, index) => {
        return cloneElement(child, {
          isActive: activeIndex === index,
          onClick: onItemClick ? () => onItemClick(index) : undefined,
          scale,
          variant,
          variantDefault,
          disabled,
        });
      })}
    </StyledButtonMenu>
  );
};

export default ButtonMenu;
