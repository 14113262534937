import { useEffect, useState } from 'react'

const useXcadPrice = () => {
  const [xcadPrice, setXcadPice] = useState<string | null>(null)

  const fetchData = async () => {
    const response = await fetch('https://api.coinlore.net/api/ticker/?id=51005')
    const data = await response.json()

    setXcadPice(Number.parseFloat(data[0].price_usd).toFixed(3))
  }

  useEffect(() => {
    fetchData()
  }, [])

  return xcadPrice
}

export default useXcadPrice
