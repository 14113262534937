import { useTranslation } from '@pancakeswap/localization'
import { Flex, Link, Text } from '@pancakeswap/uikit'
import { TOKEN_RISK } from 'components/AccessRisk'

interface AccessRiskTooltipsProps {
  riskLevel: number
  hasResult: boolean
  tokenAddress?: string
  riskLevelDescription: string
}

const AccessRiskTooltips: React.FC<AccessRiskTooltipsProps> = ({
  riskLevel,
  hasResult,
  riskLevelDescription,
  tokenAddress,
}) => {
  const { t } = useTranslation()

  if (riskLevel === TOKEN_RISK.UNKNOWN || !hasResult) {
    return (
      <>
        <Text my="8px" fontSize="14px">
          {t(
            'Risk scanning is still in progress. It may take up to 5 minutes to fully scan a token which is new to the database.',
          )}
        </Text>
        <Text as="span" fontSize="14px">
          {t('Provided by')}
        </Text>
        <Link
          variant="primary-text"
          style={{ display: 'inline' }}
          ml="4px"
          external
          href="https://www.hashdit.io"
          fontSize="14px"
        >
          HashDit
        </Link>
        <Flex mt="4px">
          <Text fontSize="14px">{t('Learn more about risk rating')}</Text>
          <Link
            variant="primary-text"
            ml="4px"
            external
            href="https://hashdit.github.io/hashdit/docs/risk-level-description"
            fontSize="14px"
          >
            {t('here.')}
          </Link>
        </Flex>
      </>
    )
  }

  if (hasResult && riskLevel >= TOKEN_RISK.VERY_LOW && tokenAddress) {
    return (
      <>
        <Text my="8px" fontSize="14px">
          {riskLevelDescription}
        </Text>
        <Text as="span" fontSize="14px">
          {t('Risk scan results are provided by a third party,')}
        </Text>
        <Link
          variant="primary-text"
          style={{ display: 'inline' }}
          ml="4px"
          external
          href="https://www.hashdit.io"
          fontSize="14px"
        >
          HashDit
        </Link>
        <Flex mt="4px">
          <Text fontSize="14px">{t('Get more details from')}</Text>
          <Link
            variant="primary-text"
            ml="4px"
            external
            href={`https://dappbay.bnbchain.org/risk-scanner/${tokenAddress}`}
            fontSize="14px"
          >
            {t('RedAlarm')}
          </Link>
        </Flex>
      </>
    )
  }

  return (
    <>
      <Text my="8px" fontSize="14px">
        {t(
          'Automatic risk scanning for the selected token. This scanning result is for reference only, and should NOT be taken as investment advice.',
        )}
      </Text>
      <Text as="span" fontSize="14px">
        {t('Provided by')}
      </Text>
      <Link
        variant="primary-text"
        style={{ display: 'inline' }}
        ml="4px"
        external
        href="https://www.hashdit.io"
        fontSize="14px"
      >
        HashDit
      </Link>
      <Link
        variant="primary-text"
        mt="4px"
        external
        href="https://hashdit.github.io/hashdit/docs/risk-level-description"
        fontSize="14px"
      >
        {t('Learn More')}
      </Link>
    </>
  )
}

export default AccessRiskTooltips
