import { useTranslation } from '@pancakeswap/localization'
import { WalletModalV2 } from '@pancakeswap/ui-wallets'
import { Button, ButtonProps, useModal } from '@pancakeswap/uikit'
import { ConnectorNames, createWallets, getDocLink } from 'config/wallet'
import { useActiveChainId } from 'hooks/useActiveChainId'
import useAuth from 'hooks/useAuth'
// @ts-ignore
// eslint-disable-next-line import/extensions
import { useActiveHandle } from 'hooks/useEagerConnect.bmp.ts'
import { useMemo } from 'react'
import { useConnect } from 'wagmi'
import { logGTMWalletConnectEvent } from 'utils/customGTMEventTracking'
import Trans from './Trans'

const ConnectWalletButton = ({ children, ...props }: ButtonProps) => {
  const handleActive = useActiveHandle()
  const { login } = useAuth()
  const {
    t,
    currentLanguage: { code },
  } = useTranslation()
  const { connectAsync } = useConnect()
  const { chainId } = useActiveChainId()

  const docLink = useMemo(() => getDocLink(code), [code])

  const wallets = useMemo(() => createWallets(chainId, connectAsync), [chainId, connectAsync])

  const [onPresentWalletModal, hideModal] = useModal(
    <WalletModalV2
      docText={t('Learn How to Connect')}
      docLink={docLink}
      wallets={wallets}
      login={async (connectorId: ConnectorNames) => {
        await login(connectorId);
        hideModal();
      }}
      onWalletConnectCallBack={logGTMWalletConnectEvent}
    />,
    true,
    true,
    'walletConnectModal',
  )

  const handleClick = () => {
    if (typeof __NEZHA_BRIDGE__ !== 'undefined' && !window.ethereum) {
      handleActive()
    } else {
      onPresentWalletModal()
    }
  }

  return (
    <>
      <Button scale="lg" onClick={handleClick} {...props} variant="outline-primary">
        {children || <Trans>Connect Wallet</Trans>}
      </Button>
      <style jsx global>{`
        w3m-modal {
          position: relative;
          z-index: 99;
        }
      `}</style>
    </>
  )
}

export default ConnectWalletButton
