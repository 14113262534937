import { bscTokens } from '@pancakeswap/tokens'
import { getAddress, zeroAddress } from 'viem'

import { PoolCategory, SerializedPool, VaultKey } from '../../types'

export const livePools: SerializedPool[] = [
  {
    sousId: 0,
    stakingToken: bscTokens.xcad,
    earningToken: bscTokens.dxcad,
    lockedAddress: '0xB4aAc2126c8Ad0Ce64E7DAA9A77862940cD1D2cC',
    contractAddress: '0x15dEE75Dd77Dcf86A7060ce085d84B3BEcf300E5',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.01',
    lockedPoolId: 0,
    vaultKey: VaultKey.CakeVault,
  },
  {
    sousId: 1,
    stakingToken: bscTokens.xcad,
    earningToken: bscTokens.dxcad,
    contractAddress: '0x15dEE75Dd77Dcf86A7060ce085d84B3BEcf300E5',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.01',
    vaultKey: VaultKey.CakeFlexibleSideVault,
    isBurnable: false,
  },
].map((p) => ({
  ...p,
  contractAddress: getAddress(p.contractAddress || zeroAddress),
  lockedAddress: p.lockedAddress ? getAddress(p.lockedAddress) : undefined,
  stakingToken: p.stakingToken.serialize,
  earningToken: p.earningToken.serialize,
}))

export const pools: SerializedPool[] = livePools
