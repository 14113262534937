import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 96 48" {...props}>
      <path
        fill="#fff"
        d="M81 33h-5.7l7-9.7-3.6-9.1h5l2.4 6.4 4.2-6.4H96l-7 9.5 3.7 9.3h-5L85 26.5 80.9 33Zm-7.4-4.2-.7 4H60l3.3-18.6H76l-.7 4.1h-7.9l-.5 3H74l-.7 4h-7.1l-.7 3.5h8.1Z"
      />
      <path
        fill="url(#a)"
        d="M31.9 48H11.5L20 0h17.6c4 0 7.7.9 10.8 2.6A19.6 19.6 0 0 1 59 20c.1 4-.4 7.8-1.7 11.2A25.1 25.1 0 0 1 43 46a30 30 0 0 1-11 2Zm-1.2-37.3-4.1 26.7h6c2.3-.3 5-.7 6.8-1.4 2-.7 3.3-1.4 5-2.8a13 13 0 0 0 4-5.7c.5-2 .8-4.3.7-6.9 0-2-.5-3.9-1.4-5.3-.9-1.5-2-2.6-3.6-3.4a12 12 0 0 0-5.5-1.2h-7.9Z"
      />
      <path fill="#fff" d="M11.4 23.1 5.7 10.7h8.2l3 7.3 5.3-7.3h8.5l-10.2 13 6 13.7H18L15 29l-6.1 8.4H0L11.4 23Z" />
      <defs>
        <linearGradient id="a" x1="26.1" x2="87.9" y1="48" y2="-1.4" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FD5C27" />
          <stop offset=".5" stopColor="#FC1557" />
          <stop offset="1" stopColor="#AC269A" />
        </linearGradient>
      </defs>
    </Svg>
  );
};

export default Icon;
