import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      viewBox="0 0 24 24"
      {...props}
    >
      <circle fill="none" cx="12" cy="12" r="9" />
      <path fill="none" d="M11 15.5h2.31m-1.15 0v-4.25H11m1.1-3a.25.25 0 1 1-.25-.25" />
      <path fill="none" d="M11.85 8c.14 0 .25.1.25.25" />
    </Svg>
  );
};

export default Icon;
